import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateItemMutationVariables = Types.Exact<{
  updateItemId: Types.Scalars['ID']['input'];
  item: Types.ItemInput;
}>;

export type UpdateItemMutation = {
  __typename?: 'Mutation';
  updateItem: {
    __typename?: 'Item';
    id: string;
    name?: string | undefined;
    type: Types.Item_Type;
    unitPrice?: number | undefined;
    unitType?: Types.Item_Unit_Type | undefined;
    taxRate?: number | undefined;
    vatRate?: Types.Item_Vat_Rate | undefined;
    vatExemptionReason?: string | undefined;
    withholdingTaxPercent?: number | undefined;
    withholdingTaxType?: Types.Invoice_Item_Withholding_Tax_Type | undefined;
    withholdingTaxAvailable?: boolean | undefined;
    withholdingTaxReason?: string | undefined;
  };
};

export const UpdateItemDocument = gql`
  mutation UpdateItem($updateItemId: ID!, $item: ItemInput!) {
    updateItem(id: $updateItemId, item: $item) {
      id
      name
      type
      unitPrice
      unitType
      taxRate
      vatRate
      vatExemptionReason
      withholdingTaxPercent
      withholdingTaxType
      withholdingTaxAvailable
      withholdingTaxReason
    }
  }
`;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      updateItemId: // value for 'updateItemId'
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
}
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
