import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetrySyncWithAtMutationVariables = Types.Exact<{
  retrySyncWithAtId: Types.Scalars['ID']['input'];
}>;

export type RetrySyncWithAtMutation = {
  __typename?: 'Mutation';
  retrySyncWithAT: {
    __typename?: 'Invoice';
    id: string;
    status: Types.Invoice_Status;
    syncWithAt?:
      | {
          __typename?: 'InvoiceSyncWithAT';
          status?: Types.Invoice_Sync_With_At_Status | undefined;
          atCode?: string | undefined;
          atMessage?: string | undefined;
          systemError?: string | undefined;
          isRetriable?: boolean | undefined;
          updatedAt?: Date | string | undefined;
        }
      | undefined;
  };
};

export const RetrySyncWithAtDocument = gql`
  mutation RetrySyncWithAT($retrySyncWithAtId: ID!) {
    retrySyncWithAT(id: $retrySyncWithAtId) {
      id
      status
      syncWithAt {
        status
        atCode
        atMessage
        systemError
        isRetriable
        updatedAt
      }
    }
  }
`;
export type RetrySyncWithAtMutationFn = Apollo.MutationFunction<
  RetrySyncWithAtMutation,
  RetrySyncWithAtMutationVariables
>;

/**
 * __useRetrySyncWithAtMutation__
 *
 * To run a mutation, you first call `useRetrySyncWithAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrySyncWithAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrySyncWithAtMutation, { data, loading, error }] = useRetrySyncWithAtMutation({
 *   variables: {
 *      retrySyncWithAtId: // value for 'retrySyncWithAtId'
 *   },
 * });
 */
export function useRetrySyncWithAtMutation(
  baseOptions?: Apollo.MutationHookOptions<RetrySyncWithAtMutation, RetrySyncWithAtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetrySyncWithAtMutation, RetrySyncWithAtMutationVariables>(
    RetrySyncWithAtDocument,
    options
  );
}
export type RetrySyncWithAtMutationHookResult = ReturnType<typeof useRetrySyncWithAtMutation>;
export type RetrySyncWithAtMutationResult = Apollo.MutationResult<RetrySyncWithAtMutation>;
export type RetrySyncWithAtMutationOptions = Apollo.BaseMutationOptions<
  RetrySyncWithAtMutation,
  RetrySyncWithAtMutationVariables
>;
