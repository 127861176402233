import { Lang } from '@/graphql-types';

export const I18N_LANG = 'i18nextLng';

export enum Language {
  PT = 'pt',
  EN = 'en',
  ES = 'es',
  IT = 'it',
}

export const getLanguageFromLocalStorage = (): Language => {
  return localStorage.getItem(I18N_LANG) as Language;
};

export const setLanguageToLocalStorage = (lng: string): void => {
  localStorage.setItem(I18N_LANG, lng);
};

export function getLanguageByI18N(lang: string) {
  const lowerLang = lang?.toLowerCase() || 'pt';
  const lngMap: { [key: string]: Lang } = {
    en: Lang.En,
    pt: Lang.Pt,
    es: Lang.Es,
    it: Lang.It,
  };

  return lowerLang in lngMap ? lngMap[lowerLang] : Lang.Pt;
}
