import * as Types from '../../types';

import { gql } from '@apollo/client';
import { CustomerCommonFieldsFragmentDoc } from '../../fragments/customerFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomersQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.CustomerFindQuery>;
  searchParams?: Types.InputMaybe<Types.FindSearchParams>;
  params?: Types.InputMaybe<Types.FindPaginationParams>;
}>;

export type GetCustomersQuery = {
  __typename?: 'Query';
  customers: Array<{
    __typename?: 'Customer';
    id: string;
    taxpayerNumber?: string | undefined;
    name?: string | undefined;
    photo?: string | undefined;
    companyName?: string | undefined;
    companyPosition?: string | undefined;
    email?: string | undefined;
    website?: string | undefined;
    description?: string | undefined;
    address?: string | undefined;
    postalCode?: string | undefined;
    country?: string | undefined;
    phone?: string | undefined;
    fax?: string | undefined;
    mobile?: string | undefined;
    city?: string | undefined;
  }>;
};

export const GetCustomersDocument = gql`
  query GetCustomers($query: CustomerFindQuery, $searchParams: FindSearchParams, $params: FindPaginationParams) {
    customers(query: $query, searchParams: $searchParams, params: $params) {
      ...CustomerCommonFields
    }
  }
  ${CustomerCommonFieldsFragmentDoc}
`;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      searchParams: // value for 'searchParams'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export function useGetCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export function useGetCustomersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersSuspenseQueryHookResult = ReturnType<typeof useGetCustomersSuspenseQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
