import * as Types from '../types';

import { gql } from '@apollo/client';
export type InvoiceSummaryFragmentFragment = {
  __typename?: 'InvoiceSummary';
  amountWithoutTax?: number | undefined;
  amountWithTax?: number | undefined;
  amountWithoutTaxWithoutDiscount?: number | undefined;
  globalDiscountType?: Types.Discount_Type | undefined;
  globalDiscountPercent?: number | undefined;
  globalDiscountAmount?: number | undefined;
  taxAmount?: number | undefined;
  total?: number | undefined;
  withholdingTaxAmount?: number | undefined;
  tax?:
    | Array<
        | {
            __typename?: 'InvoiceSummaryTax';
            name?: string | undefined;
            rate?: number | undefined;
            amount?: number | undefined;
            incidence?: number | undefined;
          }
        | undefined
      >
    | undefined;
};

export const InvoiceSummaryFragmentFragmentDoc = gql`
  fragment InvoiceSummaryFragment on InvoiceSummary {
    amountWithoutTax
    amountWithTax
    amountWithoutTaxWithoutDiscount
    globalDiscountType
    globalDiscountPercent
    globalDiscountAmount
    taxAmount
    tax {
      name
      rate
      amount
      incidence
    }
    total
    withholdingTaxAmount
  }
`;
