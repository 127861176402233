export enum READ_ONLY_GTM_EVENTS {
  SEND_FORM_SUCCESS = 'send_form_success',
}

export enum READ_ONLY_GTM_EVENT_TYPES {
  REG_GOOGLE_SUCCESS = 'reg_google_success',
  REG_EMAIL_STEP1 = 'reg_email_step1',
  REG_EMAIL_SUCCESS = 'reg_email_success',
  REG_CHOOSE_USER_TYPE = 'reg_choose_user_type',
  REG_SUCCESS = 'reg_success',
  AUTH_GOOGLE_SUCCESS = 'auth_google_success',
  AUTH_EMAIL_STEP1 = 'auth_email_step1',
  AUTH_EMAIL_SUCCESS = 'auth_email_success',
}

export const pushToGTMDataLayer = (event: READ_ONLY_GTM_EVENTS, type: READ_ONLY_GTM_EVENT_TYPES) => {
  if (!window.dataLayer) {
    console.error('GTM not initialized');
    return;
  }

  window.dataLayer.push({
    event,
    event_type: type,
  });
};
