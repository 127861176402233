import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendMagicCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  lang: Types.Lang;
}>;


export type SendMagicCodeMutation = { __typename?: 'Mutation', sendMagicCode: { __typename?: 'SendMagicCodeResponse', email: string, success: boolean } };


export const SendMagicCodeDocument = gql`
    mutation SendMagicCode($email: String!, $lang: LANG!) {
  sendMagicCode(email: $email, lang: $lang) {
    email
    success
  }
}
    `;
export type SendMagicCodeMutationFn = Apollo.MutationFunction<SendMagicCodeMutation, SendMagicCodeMutationVariables>;

/**
 * __useSendMagicCodeMutation__
 *
 * To run a mutation, you first call `useSendMagicCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicCodeMutation, { data, loading, error }] = useSendMagicCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSendMagicCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendMagicCodeMutation, SendMagicCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMagicCodeMutation, SendMagicCodeMutationVariables>(SendMagicCodeDocument, options);
      }
export type SendMagicCodeMutationHookResult = ReturnType<typeof useSendMagicCodeMutation>;
export type SendMagicCodeMutationResult = Apollo.MutationResult<SendMagicCodeMutation>;
export type SendMagicCodeMutationOptions = Apollo.BaseMutationOptions<SendMagicCodeMutation, SendMagicCodeMutationVariables>;