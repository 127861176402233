import { ComboboxItem, SelectProps, Select, NativeSelect } from '@mantine/core';
import { useField, useFormikContext } from 'formik';
import classes from './CustomInput.module.css';
// import { Select } from '../vendor/mantine/Select/Select';
import { useMediaQuery } from '@mantine/hooks';
import pick from 'lodash/pick';
import { cn } from '@/utils';
// import {
//   Select as MobileSelect,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from '../vendor/shadcnui/Select/Select';
// import { cn } from '@/utils';

interface FormikSelectProps extends SelectProps {
  name: string;
  data: { value: string; label: string }[];
  placeholder?: string;
  styles?: Record<string, string | number>;
  rest?: Record<string, unknown>;
  customClassNames?: Record<string, string>;
}

const FormikSelect = ({
  name,
  data,
  placeholder,
  label,
  styles,
  error,
  onChange,
  customClassNames,
  value,
  ...rest
}: FormikSelectProps) => {
  const isSmScreen = useMediaQuery('(max-width: 575px)');
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const nativeProps = pick(rest, ['mt', 'disabled']);

  const handleOnChange = (value: string | null, option?: ComboboxItem) => {
    if (!value && !rest.clearable) return;

    if (onChange) {
      // @ts-expect-error
      onChange(value, option);
    } else {
      setFieldValue(name, value);
    }
  };

  const mergedClasses = {
    ...classes,
    input: cn(customClassNames, !value && !field.value ? classes.inputEmpty : classes.input),
  };

  // if (isSmScreen === true) {
  //   return (
  //     <div className="relative">
  //       <MobileSelect
  //         value={value || field.value || ''}
  //         onValueChange={handleOnChange}
  //         defaultValue={data[0]?.value}
  //         disabled={rest.disabled}
  //       >
  //         <InputLabel className={classes.label} size="md">
  //           {field.value ? label : ''}
  //         </InputLabel>
  //         <SelectTrigger
  //           className={cn(
  //             !value && !field.value ? classes.inputEmpty : classes.input,
  //             rest.className,
  //             'rounded-[16px]'
  //           )}
  //         >
  //           <SelectValue placeholder={placeholder} />
  //         </SelectTrigger>
  //         <SelectContent>
  //           {data.map((item) => (
  //             <SelectItem
  //               key={item.value}
  //               value={item.value}
  //               className="hover:bg-[#f8f9fa] focus:bg-[#f8f9fa] active:bg-[#f8f9fa]"
  //             >
  //               {item.label}
  //             </SelectItem>
  //           ))}
  //         </SelectContent>
  //       </MobileSelect>
  //     </div>
  //   );
  // }

  if (isSmScreen === true) {
    return (
      <NativeSelect
        {...styles}
        value={value || field.value}
        label={(field.value as string) ? label : ''}
        // @ts-expect-error hidden is supported but missing in mantine types
        data={placeholder ? [{ value: '', label: placeholder, hidden: true }, ...data] : data}
        id={name}
        onChange={(event) => handleOnChange(event.currentTarget.value)}
        error={error}
        classNames={mergedClasses}
        size={(styles?.size as string) || 'md'}
        {...nativeProps}
      />
    );
  }

  return (
    <Select
      {...styles}
      value={value || field.value}
      placeholder={placeholder}
      label={field.value ? label : ''}
      data={data}
      id={name}
      onChange={handleOnChange}
      error={error}
      classNames={mergedClasses}
      size={(styles?.size as string) || 'md'}
      {...rest}
    />
  );
};

export default FormikSelect;
