import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSaftPtExportQueryVariables = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;

export type GetSaftPtExportQuery = {
  __typename?: 'Query';
  exportSAFTPT: { __typename?: 'ExportSAFTPTResponse'; signedUrl?: string | undefined };
};

export const GetSaftPtExportDocument = gql`
  query getSaftPtExport($startDate: DateTime, $endDate: DateTime) {
    exportSAFTPT(startDate: $startDate, endDate: $endDate) {
      signedUrl
    }
  }
`;

/**
 * __useGetSaftPtExportQuery__
 *
 * To run a query within a React component, call `useGetSaftPtExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaftPtExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaftPtExportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetSaftPtExportQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>(GetSaftPtExportDocument, options);
}
export function useGetSaftPtExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>(GetSaftPtExportDocument, options);
}
export function useGetSaftPtExportSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>(GetSaftPtExportDocument, options);
}
export type GetSaftPtExportQueryHookResult = ReturnType<typeof useGetSaftPtExportQuery>;
export type GetSaftPtExportLazyQueryHookResult = ReturnType<typeof useGetSaftPtExportLazyQuery>;
export type GetSaftPtExportSuspenseQueryHookResult = ReturnType<typeof useGetSaftPtExportSuspenseQuery>;
export type GetSaftPtExportQueryResult = Apollo.QueryResult<GetSaftPtExportQuery, GetSaftPtExportQueryVariables>;
