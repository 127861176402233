import i18n, { TFunction, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpApi from 'i18next-http-backend';

import enCommon from './lang/en/common.json';
import ptCommon from './lang/pt/common.json';
import itCommon from './lang/it/common.json';
import esCommon from './lang/es/common.json';
import { Language, getLanguageFromLocalStorage, setLanguageToLocalStorage } from './utils';

const supportedLanguages = [Language.EN, Language.PT, Language.IT, Language.ES];

const backendResources: { resources: Record<string, { [key: string]: { [key: string]: string } }> } = {
  resources: {
    en: {
      common: enCommon,
    },
    pt: {
      common: ptCommon,
    },
    it: {
      common: itCommon,
    },
    es: {
      common: esCommon,
    },
  },
};

type Namespace = 'fiz' | 'common';
const defaultNS: Namespace[] = ['fiz', 'common'];

export default function initI18N(namespaces: Namespace[] = []): Promise<TFunction> {
  // const httpBackendOptions = {
  //   backend: {
  //     loadPath: (lngs: string[], nss: string[]) => {
  //       const lookupLng = lngs?.[0];
  //       const lookupNs = nss?.[0];

  //       return backendResources?.resources?.[lookupLng]?.[lookupNs];
  //     },
  //   },
  // };

  // const isDev = import.meta.env.MODE === 'development';

  // const backendOptions = !isDev ? httpBackendOptions : backendResources;
  const backendOptions = backendResources;

  const getLangFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');

    if (lang) {
      params.delete('lang');
      const newUrl = params.toString() ? `${window.location.pathname}?${params.toString()}` : window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }

    return lang;
  };

  const getInitialLanguage = () => {
    const urlLang = getLangFromUrl();

    if (urlLang) {
      setLanguageToLocalStorage(urlLang);
      return urlLang;
    }

    const savedLanguage = getLanguageFromLocalStorage();

    if (savedLanguage) {
      return savedLanguage;
    }

    return Language.PT;
  };

  const options: InitOptions = {
    lng: getInitialLanguage(),
    fallbackLng: Language.EN,
    ns: [...defaultNS, ...namespaces],
    defaultNS: 'common',
    supportedLngs: supportedLanguages,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
    },
    ...backendOptions,
  };

  const initFn = i18n.use(LanguageDetector).use(initReactI18next);

  // if (!isDev) {
  //   initFn.use(HttpApi);
  // }

  return initFn.init(options);
}
