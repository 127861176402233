import * as Types from '../../types';

import { gql } from '@apollo/client';
import { TenantCommonFieldsFragmentDoc } from '../../fragments/tenantFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTenantsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTenantsQuery = {
  __typename?: 'Query';
  tenants: Array<{
    __typename?: 'Tenant';
    id: string;
    ownerUserId?: string | undefined;
    name?: string | undefined;
    commercialName?: string | undefined;
    cae?: Array<string | undefined> | undefined;
    postalCode?: string | undefined;
    taxPayerNumber?: string | undefined;
    territory?: Types.Tenant_Pt_Territory | undefined;
    country?: string | undefined;
    city?: string | undefined;
    verifiedAddressDetails?: boolean | undefined;
    address?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    currency?: string | undefined;
    type?: Types.Tenant_Type | undefined;
    subtype?: string | undefined;
    acceptedTosAndPrivacyPolicy?: boolean | undefined;
    language?: string | undefined;
    providesServices?: boolean | undefined;
    sellsGoods?: boolean | undefined;
    iban?: string | undefined;
    subtypeFreeInput?: string | undefined;
    isInitial?: boolean | undefined;
    isEnabledSyncWithAT?: boolean | undefined;
    customization?:
      | {
          __typename?: 'TenantCustomization';
          invoicing?:
            | {
                __typename?: 'TenantCustomizationInvoicing';
                logoFileId?: string | undefined;
                logoFile?:
                  | { __typename?: 'File'; id: string; url?: string | undefined; name?: string | undefined }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
    subtypeData?:
      | {
          __typename?: 'TenantSubtypeEntry';
          id: string;
          localizations: Array<{ __typename?: 'TenantSubtype'; id: string; name: string; locale: string }>;
        }
      | undefined;
    onboarding?:
      | {
          __typename?: 'TenantOnboarding';
          finished?: boolean | undefined;
          currentStep?: number | undefined;
          waitlist?: boolean | undefined;
        }
      | undefined;
    credentials?:
      | { __typename?: 'TenantCredentials'; username?: string | undefined; verified?: boolean | undefined }
      | undefined;
  }>;
};

export const GetTenantsDocument = gql`
  query GetTenants {
    tenants {
      ...TenantCommonFields
    }
  }
  ${TenantCommonFieldsFragmentDoc}
`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
}
export function useGetTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
}
export function useGetTenantsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsSuspenseQueryHookResult = ReturnType<typeof useGetTenantsSuspenseQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
