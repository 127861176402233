import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSerieMutationVariables = Types.Exact<{
  updateSerieId: Types.Scalars['ID']['input'];
  serie: Types.SerieInput;
}>;


export type UpdateSerieMutation = { __typename?: 'Mutation', updateSerie: { __typename?: 'Serie', id: string, name?: string | undefined, status?: Types.Serie_Status | undefined, managementMode?: Types.Serie_Management_Mode | undefined, startDate?: Date | string | undefined, endDate?: Date | string | undefined, tenantId?: string | undefined, entries?: Array<{ __typename?: 'SerieEntry', documentType?: Types.Invoice_Document_Type | undefined, validationCode?: string | undefined, isVerified?: boolean | undefined } | undefined> | undefined } };


export const UpdateSerieDocument = gql`
    mutation UpdateSerie($updateSerieId: ID!, $serie: SerieInput!) {
  updateSerie(id: $updateSerieId, serie: $serie) {
    id
    name
    status
    managementMode
    startDate
    endDate
    entries {
      documentType
      validationCode
      isVerified
    }
    tenantId
  }
}
    `;
export type UpdateSerieMutationFn = Apollo.MutationFunction<UpdateSerieMutation, UpdateSerieMutationVariables>;

/**
 * __useUpdateSerieMutation__
 *
 * To run a mutation, you first call `useUpdateSerieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSerieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSerieMutation, { data, loading, error }] = useUpdateSerieMutation({
 *   variables: {
 *      updateSerieId: // value for 'updateSerieId'
 *      serie: // value for 'serie'
 *   },
 * });
 */
export function useUpdateSerieMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSerieMutation, UpdateSerieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSerieMutation, UpdateSerieMutationVariables>(UpdateSerieDocument, options);
      }
export type UpdateSerieMutationHookResult = ReturnType<typeof useUpdateSerieMutation>;
export type UpdateSerieMutationResult = Apollo.MutationResult<UpdateSerieMutation>;
export type UpdateSerieMutationOptions = Apollo.BaseMutationOptions<UpdateSerieMutation, UpdateSerieMutationVariables>;