import { RESEND_CODE_TIMESTAMP_STORAGE_KEY } from '@/config/constants';
import { useState, useEffect, useCallback, useRef } from 'react';

const useResendCodeCounter = (initialTimeInMinutes: number = 10) => {
  const initialTimeInSeconds = initialTimeInMinutes * 60;
  const [timeLeft, setTimeLeft] = useState<number>(initialTimeInSeconds);
  const [isCounting, setIsCounting] = useState<boolean>(true);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      const storedTimestamp = localStorage.getItem(RESEND_CODE_TIMESTAMP_STORAGE_KEY);
      if (storedTimestamp) {
        const elapsedSeconds = Math.floor((Date.now() - parseInt(storedTimestamp, 10)) / 1000);
        const remainingTime = initialTimeInSeconds - elapsedSeconds;

        if (remainingTime <= 0) {
          clearInterval(timerRef.current!);
          setIsCounting(false);
          setTimeLeft(0);
        } else {
          setTimeLeft(remainingTime);
        }
      }
    }, 1000);

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [initialTimeInSeconds]);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem(RESEND_CODE_TIMESTAMP_STORAGE_KEY);
    if (storedTimestamp) {
      const elapsedSeconds = Math.floor((Date.now() - parseInt(storedTimestamp, 10)) / 1000);
      const remainingTime = initialTimeInSeconds - elapsedSeconds;
      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
      } else {
        setIsCounting(false);
      }
    } else {
      localStorage.setItem(RESEND_CODE_TIMESTAMP_STORAGE_KEY, Date.now().toString());
    }

    const cleanup = startTimer();
    return cleanup;
  }, [initialTimeInSeconds, startTimer]);

  const handleResendClick = useCallback(() => {
    const newTimestamp = Date.now();
    localStorage.setItem(RESEND_CODE_TIMESTAMP_STORAGE_KEY, newTimestamp.toString());
    setTimeLeft(initialTimeInSeconds);
    setIsCounting(true);
    startTimer();
  }, [initialTimeInSeconds, startTimer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return { isCounting, handleResendClick, formattedTimeLeft: formatTime(timeLeft) };
};

export default useResendCodeCounter;
