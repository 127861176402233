import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSeriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSeriesQuery = {
  __typename?: 'Query';
  series: Array<{
    __typename?: 'Serie';
    id: string;
    name?: string | undefined;
    status?: Types.Serie_Status | undefined;
    managementMode?: Types.Serie_Management_Mode | undefined;
    startDate?: Date | string | undefined;
    endDate?: Date | string | undefined;
    tenantId?: string | undefined;
    entries?:
      | Array<
          | {
              __typename?: 'SerieEntry';
              documentType?: Types.Invoice_Document_Type | undefined;
              validationCode?: string | undefined;
              isVerified?: boolean | undefined;
            }
          | undefined
        >
      | undefined;
  }>;
};

export const GetSeriesDocument = gql`
  query GetSeries {
    series {
      id
      name
      status
      managementMode
      startDate
      endDate
      entries {
        documentType
        validationCode
        isVerified
      }
      tenantId
    }
  }
`;

/**
 * __useGetSeriesQuery__
 *
 * To run a query within a React component, call `useGetSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSeriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
}
export function useGetSeriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
}
export function useGetSeriesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
}
export type GetSeriesQueryHookResult = ReturnType<typeof useGetSeriesQuery>;
export type GetSeriesLazyQueryHookResult = ReturnType<typeof useGetSeriesLazyQuery>;
export type GetSeriesSuspenseQueryHookResult = ReturnType<typeof useGetSeriesSuspenseQuery>;
export type GetSeriesQueryResult = Apollo.QueryResult<GetSeriesQuery, GetSeriesQueryVariables>;
