import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectBankAccountMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ConnectBankAccountMutation = { __typename?: 'Mutation', connectBankAccount: { __typename?: 'ConnectBankAccountResponse', success: boolean, linkToken?: string | undefined } };


export const ConnectBankAccountDocument = gql`
    mutation ConnectBankAccount {
  connectBankAccount {
    success
    linkToken
  }
}
    `;
export type ConnectBankAccountMutationFn = Apollo.MutationFunction<ConnectBankAccountMutation, ConnectBankAccountMutationVariables>;

/**
 * __useConnectBankAccountMutation__
 *
 * To run a mutation, you first call `useConnectBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectBankAccountMutation, { data, loading, error }] = useConnectBankAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useConnectBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConnectBankAccountMutation, ConnectBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectBankAccountMutation, ConnectBankAccountMutationVariables>(ConnectBankAccountDocument, options);
      }
export type ConnectBankAccountMutationHookResult = ReturnType<typeof useConnectBankAccountMutation>;
export type ConnectBankAccountMutationResult = Apollo.MutationResult<ConnectBankAccountMutation>;
export type ConnectBankAccountMutationOptions = Apollo.BaseMutationOptions<ConnectBankAccountMutation, ConnectBankAccountMutationVariables>;