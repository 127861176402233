import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IssueInvoiceMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  invoice?: Types.InputMaybe<Types.InvoiceInput>;
}>;

export type IssueInvoiceMutation = {
  __typename?: 'Mutation';
  issueInvoice: {
    __typename?: 'Invoice';
    id: string;
    documentType?: Types.Invoice_Document_Type | undefined;
    status: Types.Invoice_Status;
    notes?: string | undefined;
    dueDate?: Date | string | undefined;
    date?: Date | string | undefined;
    series?: { __typename?: 'InvoiceSerie'; ref?: string | undefined; name?: string | undefined } | undefined;
    payment?:
      | { __typename?: 'InvoicePayment'; date?: Date | string | undefined; method?: string | undefined }
      | undefined;
    customer?:
      | {
          __typename?: 'InvoiceCustomer';
          ref?: string | undefined;
          data?:
            | {
                __typename?: 'InvoiceCustomerData';
                taxpayerNumber?: string | undefined;
                name?: string | undefined;
                email?: string | undefined;
                country?: string | undefined;
                phone?: string | undefined;
              }
            | undefined;
        }
      | undefined;
    items?:
      | Array<
          | {
              __typename?: 'InvoiceItem';
              id: string;
              ref?: string | undefined;
              meta?:
                | { __typename?: 'InvoiceItemMeta'; quantity?: number | undefined; taxAmount?: number | undefined }
                | undefined;
              data?:
                | {
                    __typename?: 'InvoiceItemData';
                    name?: string | undefined;
                    type: Types.Item_Type;
                    unitPrice?: number | undefined;
                    taxRate?: number | undefined;
                    vatExemptionReason?: string | undefined;
                  }
                | undefined;
            }
          | undefined
        >
      | undefined;
    syncWithAt?:
      | {
          __typename?: 'InvoiceSyncWithAT';
          status?: Types.Invoice_Sync_With_At_Status | undefined;
          atCode?: string | undefined;
          atMessage?: string | undefined;
          systemError?: string | undefined;
          isRetriable?: boolean | undefined;
          updatedAt?: Date | string | undefined;
        }
      | undefined;
  };
};

export const IssueInvoiceDocument = gql`
  mutation IssueInvoice($id: ID, $invoice: InvoiceInput) {
    issueInvoice(id: $id, invoice: $invoice) {
      id
      documentType
      status
      notes
      series {
        ref
        name
      }
      payment {
        date
        method
      }
      customer {
        ref
        data {
          taxpayerNumber
          name
          email
          country
          phone
        }
      }
      dueDate
      date
      items {
        id
        ref
        meta {
          quantity
          taxAmount
        }
        data {
          name
          type
          unitPrice
          taxRate
          vatExemptionReason
        }
      }
      syncWithAt {
        status
        atCode
        atMessage
        systemError
        isRetriable
        updatedAt
      }
    }
  }
`;
export type IssueInvoiceMutationFn = Apollo.MutationFunction<IssueInvoiceMutation, IssueInvoiceMutationVariables>;

/**
 * __useIssueInvoiceMutation__
 *
 * To run a mutation, you first call `useIssueInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueInvoiceMutation, { data, loading, error }] = useIssueInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useIssueInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<IssueInvoiceMutation, IssueInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueInvoiceMutation, IssueInvoiceMutationVariables>(IssueInvoiceDocument, options);
}
export type IssueInvoiceMutationHookResult = ReturnType<typeof useIssueInvoiceMutation>;
export type IssueInvoiceMutationResult = Apollo.MutationResult<IssueInvoiceMutation>;
export type IssueInvoiceMutationOptions = Apollo.BaseMutationOptions<
  IssueInvoiceMutation,
  IssueInvoiceMutationVariables
>;
