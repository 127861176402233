import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemovePortugalAtCredentialsMutationVariables = Types.Exact<{
  removePortugalAtCredentialsId: Types.Scalars['ID']['input'];
}>;

export type RemovePortugalAtCredentialsMutation = {
  __typename?: 'Mutation';
  removePortugalATCredentials: {
    __typename?: 'Tenant';
    id: string;
    credentials?:
      | { __typename?: 'TenantCredentials'; username?: string | undefined; verified?: boolean | undefined }
      | undefined;
  };
};

export const RemovePortugalAtCredentialsDocument = gql`
  mutation RemovePortugalATCredentials($removePortugalAtCredentialsId: ID!) {
    removePortugalATCredentials(id: $removePortugalAtCredentialsId) {
      id
      credentials {
        username
        verified
      }
    }
  }
`;
export type RemovePortugalAtCredentialsMutationFn = Apollo.MutationFunction<
  RemovePortugalAtCredentialsMutation,
  RemovePortugalAtCredentialsMutationVariables
>;

/**
 * __useRemovePortugalAtCredentialsMutation__
 *
 * To run a mutation, you first call `useRemovePortugalAtCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePortugalAtCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePortugalAtCredentialsMutation, { data, loading, error }] = useRemovePortugalAtCredentialsMutation({
 *   variables: {
 *      removePortugalAtCredentialsId: // value for 'removePortugalAtCredentialsId'
 *   },
 * });
 */
export function useRemovePortugalAtCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePortugalAtCredentialsMutation,
    RemovePortugalAtCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePortugalAtCredentialsMutation, RemovePortugalAtCredentialsMutationVariables>(
    RemovePortugalAtCredentialsDocument,
    options
  );
}
export type RemovePortugalAtCredentialsMutationHookResult = ReturnType<typeof useRemovePortugalAtCredentialsMutation>;
export type RemovePortugalAtCredentialsMutationResult = Apollo.MutationResult<RemovePortugalAtCredentialsMutation>;
export type RemovePortugalAtCredentialsMutationOptions = Apollo.BaseMutationOptions<
  RemovePortugalAtCredentialsMutation,
  RemovePortugalAtCredentialsMutationVariables
>;
