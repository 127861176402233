import { NumberInput, NumberInputProps } from '@mantine/core';
import { useField, useFormikContext } from 'formik';
import classes from './CustomInput.module.css';

export interface FormikNumberInputProps extends NumberInputProps {
  name: string;
  styles?: Record<string, string | number>;
  customClassNames?: Record<string, string>;
  label?: string;
}

const FormikNumberInput = ({ ...props }: FormikNumberInputProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name);
  const { label, customClassNames, ...rest } = props;

  const handleOnChange = (value: string | number) => {
    if (props.onChange) {
      props.onChange(value);
    } else {
      setFieldValue(props.name, value);
    }
  };

  const mergedClasses = {
    ...(label ? classes : {}),
    input: `${customClassNames?.input} ${label ? classes.input : ''}`,
    ...customClassNames,
  };

  let normalizedValue = field.value;

  if (props.inputMode === 'decimal' && normalizedValue === '.') {
    normalizedValue = '0.';
  }

  return (
    <NumberInput
      {...rest}
      {...field}
      size={props.size || 'md'}
      value={normalizedValue}
      onChange={handleOnChange}
      label={normalizedValue || normalizedValue === 0 ? label : ''}
      placeholder={props.placeholder}
      classNames={mergedClasses}
    />
  );
};

export default FormikNumberInput;
