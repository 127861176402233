import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FullInvoiceDetailsFieldsFragmentDoc } from '../../fragments/fullInvoiceDetailsFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInvoiceMutationVariables = Types.Exact<{
  updateInvoiceId: Types.Scalars['ID']['input'];
  invoice: Types.InvoiceInput;
}>;

export type UpdateInvoiceMutation = {
  __typename?: 'Mutation';
  updateInvoice: {
    __typename?: 'Invoice';
    id: string;
    number?: string | undefined;
    atcud?: string | undefined;
    shortHash?: string | undefined;
    documentType?: Types.Invoice_Document_Type | undefined;
    status: Types.Invoice_Status;
    createdAt?: Date | string | undefined;
    issuedAt?: Date | string | undefined;
    canceledAt?: Date | string | undefined;
    notes?: string | undefined;
    description?: string | undefined;
    date?: Date | string | undefined;
    dueDate?: Date | string | undefined;
    currency?: string | undefined;
    ownerUserId?: string | undefined;
    parentInvoiceId?: string | undefined;
    parentInvoiceNumber?: string | undefined;
    parentInvoiceDate?: Date | string | undefined;
    parentInvoiceType?: Types.Invoice_Document_Type | undefined;
    reason?: string | undefined;
    series?: { __typename?: 'InvoiceSerie'; ref?: string | undefined; name?: string | undefined } | undefined;
    payment?:
      | { __typename?: 'InvoicePayment'; date?: Date | string | undefined; method?: string | undefined }
      | undefined;
    customer?:
      | {
          __typename?: 'InvoiceCustomer';
          ref?: string | undefined;
          data?:
            | {
                __typename?: 'InvoiceCustomerData';
                taxpayerNumber?: string | undefined;
                name?: string | undefined;
                photo?: string | undefined;
                email?: string | undefined;
                website?: string | undefined;
                address?: string | undefined;
                postalCode?: string | undefined;
                country?: string | undefined;
                phone?: string | undefined;
                fax?: string | undefined;
                mobile?: string | undefined;
                city?: string | undefined;
              }
            | undefined;
        }
      | undefined;
    summary?:
      | {
          __typename?: 'InvoiceSummary';
          amountWithoutTax?: number | undefined;
          amountWithTax?: number | undefined;
          amountWithoutTaxWithoutDiscount?: number | undefined;
          globalDiscountType?: Types.Discount_Type | undefined;
          globalDiscountPercent?: number | undefined;
          globalDiscountAmount?: number | undefined;
          taxAmount?: number | undefined;
          total?: number | undefined;
          withholdingTaxAmount?: number | undefined;
          tax?:
            | Array<
                | {
                    __typename?: 'InvoiceSummaryTax';
                    name?: string | undefined;
                    rate?: number | undefined;
                    amount?: number | undefined;
                    incidence?: number | undefined;
                  }
                | undefined
              >
            | undefined;
        }
      | undefined;
    items?:
      | Array<
          | {
              __typename?: 'InvoiceItem';
              id: string;
              ref?: string | undefined;
              data?:
                | {
                    __typename?: 'InvoiceItemData';
                    name?: string | undefined;
                    type: Types.Item_Type;
                    unitPrice?: number | undefined;
                    taxRate?: number | undefined;
                    vatRate?: Types.Item_Vat_Rate | undefined;
                    vatExemptionReason?: string | undefined;
                    unitType?: Types.Item_Unit_Type | undefined;
                    withholdingTaxPercent?: number | undefined;
                    withholdingTaxType?: Types.Invoice_Item_Withholding_Tax_Type | undefined;
                    withholdingTaxAvailable?: boolean | undefined;
                    withholdingTaxReason?: string | undefined;
                  }
                | undefined;
              meta?:
                | {
                    __typename?: 'InvoiceItemMeta';
                    quantity?: number | undefined;
                    amountWithoutTax?: number | undefined;
                    amountWithTax?: number | undefined;
                    amountWithoutTaxWithoutDiscount?: number | undefined;
                    taxAmount?: number | undefined;
                    discountAmount?: number | undefined;
                    unitAmountWithoutTax?: number | undefined;
                    unitAmountWithTax?: number | undefined;
                    unitAmountWithTaxWithoutDiscount?: number | undefined;
                    unitTaxAmount?: number | undefined;
                    unitDiscountAmount?: number | undefined;
                    withholdingTaxAmount?: number | undefined;
                    withholdingTaxEnabled?: boolean | undefined;
                  }
                | undefined;
            }
          | undefined
        >
      | undefined;
    parentDocument?:
      | {
          __typename?: 'Invoice';
          id: string;
          number?: string | undefined;
          atcud?: string | undefined;
          documentType?: Types.Invoice_Document_Type | undefined;
          status: Types.Invoice_Status;
          date?: Date | string | undefined;
          dueDate?: Date | string | undefined;
          currency?: string | undefined;
          payment?:
            | { __typename?: 'InvoicePayment'; date?: Date | string | undefined; method?: string | undefined }
            | undefined;
          summary?:
            | {
                __typename?: 'InvoiceSummary';
                amountWithoutTax?: number | undefined;
                amountWithTax?: number | undefined;
                amountWithoutTaxWithoutDiscount?: number | undefined;
                globalDiscountType?: Types.Discount_Type | undefined;
                globalDiscountPercent?: number | undefined;
                globalDiscountAmount?: number | undefined;
                taxAmount?: number | undefined;
                total?: number | undefined;
                withholdingTaxAmount?: number | undefined;
                tax?:
                  | Array<
                      | {
                          __typename?: 'InvoiceSummaryTax';
                          name?: string | undefined;
                          rate?: number | undefined;
                          amount?: number | undefined;
                          incidence?: number | undefined;
                        }
                      | undefined
                    >
                  | undefined;
              }
            | undefined;
        }
      | undefined;
    relatedDocuments?:
      | Array<{
          __typename?: 'Invoice';
          id: string;
          number?: string | undefined;
          atcud?: string | undefined;
          documentType?: Types.Invoice_Document_Type | undefined;
          status: Types.Invoice_Status;
          date?: Date | string | undefined;
          dueDate?: Date | string | undefined;
          currency?: string | undefined;
          payment?:
            | { __typename?: 'InvoicePayment'; date?: Date | string | undefined; method?: string | undefined }
            | undefined;
          summary?:
            | {
                __typename?: 'InvoiceSummary';
                amountWithoutTax?: number | undefined;
                amountWithTax?: number | undefined;
                amountWithoutTaxWithoutDiscount?: number | undefined;
                globalDiscountType?: Types.Discount_Type | undefined;
                globalDiscountPercent?: number | undefined;
                globalDiscountAmount?: number | undefined;
                taxAmount?: number | undefined;
                total?: number | undefined;
                withholdingTaxAmount?: number | undefined;
                tax?:
                  | Array<
                      | {
                          __typename?: 'InvoiceSummaryTax';
                          name?: string | undefined;
                          rate?: number | undefined;
                          amount?: number | undefined;
                          incidence?: number | undefined;
                        }
                      | undefined
                    >
                  | undefined;
              }
            | undefined;
        }>
      | undefined;
    qrCode?: { __typename?: 'InvoiceQrCode'; data: string; imageData: string } | undefined;
    issuer?:
      | {
          __typename?: 'InvoiceIssuer';
          ref?: string | undefined;
          data?:
            | {
                __typename?: 'InvoiceIssuerData';
                name?: string | undefined;
                commercialName?: string | undefined;
                type?: Types.Tenant_Type | undefined;
                taxPayerNumber?: string | undefined;
                country?: string | undefined;
                currency?: string | undefined;
                address?: string | undefined;
                city?: string | undefined;
                territory?: Types.Tenant_Pt_Territory | undefined;
                postalCode?: string | undefined;
                phone?: string | undefined;
                email?: string | undefined;
                language?: string | undefined;
                iban?: string | undefined;
                customization?:
                  | {
                      __typename?: 'TenantCustomization';
                      invoicing?:
                        | {
                            __typename?: 'TenantCustomizationInvoicing';
                            logoFileId?: string | undefined;
                            logoFile?:
                              | { __typename?: 'File'; id: string; url?: string | undefined; name?: string | undefined }
                              | undefined;
                          }
                        | undefined;
                    }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
    syncWithAt?:
      | {
          __typename?: 'InvoiceSyncWithAT';
          status?: Types.Invoice_Sync_With_At_Status | undefined;
          atCode?: string | undefined;
          atMessage?: string | undefined;
          systemError?: string | undefined;
          isRetriable?: boolean | undefined;
          updatedAt?: Date | string | undefined;
        }
      | undefined;
  };
};

export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($updateInvoiceId: ID!, $invoice: InvoiceInput!) {
    updateInvoice(id: $updateInvoiceId, invoice: $invoice) {
      ...FullInvoiceDetailsFields
    }
  }
  ${FullInvoiceDetailsFieldsFragmentDoc}
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      updateInvoiceId: // value for 'updateInvoiceId'
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
}
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
