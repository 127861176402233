import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectBankAccountConfirmMutationVariables = Types.Exact<{
  publicToken: Types.Scalars['String']['input'];
  metadata: Types.Scalars['String']['input'];
}>;


export type ConnectBankAccountConfirmMutation = { __typename?: 'Mutation', connectBankAccount: { __typename?: 'ConnectBankAccountResponse', success: boolean } };


export const ConnectBankAccountConfirmDocument = gql`
    mutation ConnectBankAccountConfirm($publicToken: String!, $metadata: String!) {
  connectBankAccount(publicToken: $publicToken, metadata: $metadata) {
    success
  }
}
    `;
export type ConnectBankAccountConfirmMutationFn = Apollo.MutationFunction<ConnectBankAccountConfirmMutation, ConnectBankAccountConfirmMutationVariables>;

/**
 * __useConnectBankAccountConfirmMutation__
 *
 * To run a mutation, you first call `useConnectBankAccountConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectBankAccountConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectBankAccountConfirmMutation, { data, loading, error }] = useConnectBankAccountConfirmMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useConnectBankAccountConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ConnectBankAccountConfirmMutation, ConnectBankAccountConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectBankAccountConfirmMutation, ConnectBankAccountConfirmMutationVariables>(ConnectBankAccountConfirmDocument, options);
      }
export type ConnectBankAccountConfirmMutationHookResult = ReturnType<typeof useConnectBankAccountConfirmMutation>;
export type ConnectBankAccountConfirmMutationResult = Apollo.MutationResult<ConnectBankAccountConfirmMutation>;
export type ConnectBankAccountConfirmMutationOptions = Apollo.BaseMutationOptions<ConnectBankAccountConfirmMutation, ConnectBankAccountConfirmMutationVariables>;