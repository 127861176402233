import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreviewInvoiceQueryQueryVariables = Types.Exact<{
  invoice: Types.InvoicePreviewInput;
}>;

export type PreviewInvoiceQueryQuery = {
  __typename?: 'Query';
  previewInvoice: {
    __typename?: 'InvoicePreviewData';
    items?:
      | Array<
          | {
              __typename?: 'InvoicePreviewItem';
              ref?: string | undefined;
              meta?:
                | {
                    __typename?: 'InvoicePreviewItemMeta';
                    quantity?: number | undefined;
                    amountWithoutTax?: number | undefined;
                    amountWithTax?: number | undefined;
                    taxAmount?: number | undefined;
                    discountAmount?: number | undefined;
                    unitAmountWithoutTax?: number | undefined;
                    unitAmountWithTax?: number | undefined;
                    unitAmountWithTaxWithoutDiscount?: number | undefined;
                    amountWithoutTaxWithoutDiscount?: number | undefined;
                    unitTaxAmount?: number | undefined;
                    unitDiscountAmount?: number | undefined;
                  }
                | undefined;
              data?:
                | {
                    __typename?: 'InvoicePreviewItemData';
                    unitPrice?: number | undefined;
                    taxRate?: number | undefined;
                  }
                | undefined;
            }
          | undefined
        >
      | undefined;
    summary?:
      | {
          __typename?: 'InvoicePreviewSummary';
          amountWithoutTax?: number | undefined;
          amountWithTax?: number | undefined;
          amountWithoutTaxWithoutDiscount?: number | undefined;
          globalDiscountType?: Types.Discount_Type | undefined;
          globalDiscountPercent?: number | undefined;
          globalDiscountAmount?: number | undefined;
          taxAmount?: number | undefined;
          total?: number | undefined;
          withholdingTaxAmount?: number | undefined;
          tax?:
            | Array<
                | {
                    __typename?: 'InvoiceSummaryTax';
                    name?: string | undefined;
                    rate?: number | undefined;
                    amount?: number | undefined;
                    incidence?: number | undefined;
                  }
                | undefined
              >
            | undefined;
        }
      | undefined;
  };
};

export const PreviewInvoiceQueryDocument = gql`
  query PreviewInvoiceQuery($invoice: InvoicePreviewInput!) {
    previewInvoice(invoice: $invoice) {
      items {
        ref
        meta {
          quantity
          amountWithoutTax
          amountWithTax
          taxAmount
          discountAmount
          unitAmountWithoutTax
          unitAmountWithTax
          unitAmountWithTaxWithoutDiscount
          amountWithoutTaxWithoutDiscount
          unitTaxAmount
          unitDiscountAmount
        }
        data {
          unitPrice
          taxRate
        }
      }
      summary {
        amountWithoutTax
        amountWithTax
        amountWithoutTaxWithoutDiscount
        globalDiscountType
        globalDiscountPercent
        globalDiscountAmount
        taxAmount
        tax {
          name
          rate
          amount
          incidence
        }
        total
        withholdingTaxAmount
      }
    }
  }
`;

/**
 * __usePreviewInvoiceQueryQuery__
 *
 * To run a query within a React component, call `usePreviewInvoiceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewInvoiceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewInvoiceQueryQuery({
 *   variables: {
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function usePreviewInvoiceQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PreviewInvoiceQueryQuery, PreviewInvoiceQueryQueryVariables> &
    ({ variables: PreviewInvoiceQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreviewInvoiceQueryQuery, PreviewInvoiceQueryQueryVariables>(
    PreviewInvoiceQueryDocument,
    options
  );
}
export function usePreviewInvoiceQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PreviewInvoiceQueryQuery, PreviewInvoiceQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreviewInvoiceQueryQuery, PreviewInvoiceQueryQueryVariables>(
    PreviewInvoiceQueryDocument,
    options
  );
}
export function usePreviewInvoiceQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PreviewInvoiceQueryQuery, PreviewInvoiceQueryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PreviewInvoiceQueryQuery, PreviewInvoiceQueryQueryVariables>(
    PreviewInvoiceQueryDocument,
    options
  );
}
export type PreviewInvoiceQueryQueryHookResult = ReturnType<typeof usePreviewInvoiceQueryQuery>;
export type PreviewInvoiceQueryLazyQueryHookResult = ReturnType<typeof usePreviewInvoiceQueryLazyQuery>;
export type PreviewInvoiceQuerySuspenseQueryHookResult = ReturnType<typeof usePreviewInvoiceQuerySuspenseQuery>;
export type PreviewInvoiceQueryQueryResult = Apollo.QueryResult<
  PreviewInvoiceQueryQuery,
  PreviewInvoiceQueryQueryVariables
>;
