import React, { useEffect, useState } from 'react';
import { ActionIcon, Group } from '@mantine/core';
import FlagPtIcon from '@/assets/flags/pt.svg?react';
import FlagGbIcon from '@/assets/flags/gb.svg?react';
import FlagItIcon from '@/assets/flags/it.svg?react';
import FlagEsIcon from '@/assets/flags/es.svg?react';
import i18next from 'i18next';
import { Language, setLanguageToLocalStorage } from '@/components/I18N';
import dayjs from 'dayjs';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { useUpdateTenantMutation } from '@/graphql-operations';
import { showErrorToast } from '../Toast';
import { useCountryFromParams } from '@/hooks/useCountryFromParams';

const getFlagIcon = (lang?: string | Language.PT) => {
  switch (lang) {
    case Language.PT:
      return FlagPtIcon;
    case Language.IT:
      return FlagItIcon;
    case Language.ES:
      return FlagEsIcon;
    case Language.EN:
      return FlagGbIcon;
    default:
      return FlagPtIcon;
  }
};

type LanguageSwitcherProps = {
  country?: string;
};

const languagesByCountry = [
  { country: 'PT', languages: [Language.PT, Language.EN] },
  { country: 'ES', languages: [Language.ES, Language.EN] },
  { country: 'IT', languages: [Language.IT, Language.EN] },
];

function LanguageSwitcher({ country }: LanguageSwitcherProps) {
  const tenantObject = useReactiveVar(tenantVar);
  const [selectedLang, setSelectedLang] = useState(i18next.resolvedLanguage);
  const { country: countryFromPath } = useCountryFromParams();
  const selectedCountry = countryFromPath || country;
  const countryLanguages = languagesByCountry.find((item) => item.country === selectedCountry);

  useEffect(() => {
    if (selectedLang !== 'en' && !countryLanguages?.languages.includes(selectedLang as Language)) {
      handleLangSelection(countryLanguages?.languages[0] as Language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryFromPath, countryLanguages?.languages, selectedCountry, selectedLang]);

  const [updateTenantMutation] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error', message: error.message });
    },
  });

  async function handleLangSelection(lang?: string | Language.PT) {
    if (tenantObject?.id) {
      await updateTenantMutation({
        variables: {
          updateTenantId: tenantObject.id,
          tenant: {
            language: lang,
          },
        },
      });
    }
    setSelectedLang(lang);
    setLanguageToLocalStorage(lang || Language.PT);
    await i18next.changeLanguage(lang);
    dayjs.locale(lang);
  }

  return (
    <Group className="bg-light-gray w-auto gap-0.5 rounded-full px-3 py-1">
      {countryLanguages?.languages.map((lng) => {
        const LangIcon = getFlagIcon(lng);
        return (
          <ActionIcon
            key={lng}
            variant="transparent"
            aria-label="Switch Language"
            onClick={() => handleLangSelection(lng)}
          >
            <LangIcon className="rounded-[3px]" height={selectedLang === lng ? 17 : 15} />
          </ActionIcon>
        );
      })}
    </Group>
  );
}

export default LanguageSwitcher;
