import * as Types from '../types';

import { gql } from '@apollo/client';
import { TenantCustomizationFieldsFragmentDoc } from './tenantCustomizationFragment.generated';
export type TenantCommonFieldsFragment = {
  __typename?: 'Tenant';
  id: string;
  ownerUserId?: string | undefined;
  name?: string | undefined;
  commercialName?: string | undefined;
  cae?: Array<string | undefined> | undefined;
  postalCode?: string | undefined;
  taxPayerNumber?: string | undefined;
  territory?: Types.Tenant_Pt_Territory | undefined;
  country?: string | undefined;
  city?: string | undefined;
  verifiedAddressDetails?: boolean | undefined;
  address?: string | undefined;
  phone?: string | undefined;
  email?: string | undefined;
  currency?: string | undefined;
  type?: Types.Tenant_Type | undefined;
  subtype?: string | undefined;
  acceptedTosAndPrivacyPolicy?: boolean | undefined;
  language?: string | undefined;
  providesServices?: boolean | undefined;
  sellsGoods?: boolean | undefined;
  iban?: string | undefined;
  subtypeFreeInput?: string | undefined;
  isInitial?: boolean | undefined;
  isEnabledSyncWithAT?: boolean | undefined;
  customization?:
    | {
        __typename?: 'TenantCustomization';
        invoicing?:
          | {
              __typename?: 'TenantCustomizationInvoicing';
              logoFileId?: string | undefined;
              logoFile?:
                | { __typename?: 'File'; id: string; url?: string | undefined; name?: string | undefined }
                | undefined;
            }
          | undefined;
      }
    | undefined;
  subtypeData?:
    | {
        __typename?: 'TenantSubtypeEntry';
        id: string;
        localizations: Array<{ __typename?: 'TenantSubtype'; id: string; name: string; locale: string }>;
      }
    | undefined;
  onboarding?:
    | {
        __typename?: 'TenantOnboarding';
        finished?: boolean | undefined;
        currentStep?: number | undefined;
        waitlist?: boolean | undefined;
      }
    | undefined;
  credentials?:
    | { __typename?: 'TenantCredentials'; username?: string | undefined; verified?: boolean | undefined }
    | undefined;
};

export const TenantCommonFieldsFragmentDoc = gql`
  fragment TenantCommonFields on Tenant {
    id
    ownerUserId
    name
    commercialName
    cae
    postalCode
    taxPayerNumber
    territory
    country
    city
    verifiedAddressDetails
    address
    phone
    email
    currency
    type
    subtype
    acceptedTosAndPrivacyPolicy
    language
    providesServices
    sellsGoods
    iban
    subtypeFreeInput
    isInitial
    customization {
      ...TenantCustomizationFields
    }
    subtypeData {
      localizations {
        id
        name
        locale
      }
      id
    }
    onboarding {
      finished
      currentStep
      waitlist
    }
    credentials {
      username
      verified
    }
    isEnabledSyncWithAT
  }
  ${TenantCustomizationFieldsFragmentDoc}
`;
