import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { getLanguageByI18N, useTranslation } from '@/components/I18N';
// import GoogleLogo from '@/assets/google-logo.svg?react';
// import { useGetAuthorizationUrlMutation, useSendMagicCodeMutation, useSignupMutation } from '@/graphql-operations';
import { useSendMagicCodeMutation, useSignupMutation } from '@/graphql-operations';
// import { Authorization_Provider, Lang } from '@/graphql-types';
import { READ_ONLY_GTM_EVENTS, READ_ONLY_GTM_EVENT_TYPES, pushToGTMDataLayer } from '@/utils/manageGTM';
import { FormikInput } from '../FormikWrapped';
import LanguageSwitcher from '../LanguageSwitcher';
import { showErrorToast } from '../Toast';
import validationSchema from './validation';
import { userVar } from '@/config/reactiveVars/userVar';
import { useCountryFromParams } from '@/hooks/useCountryFromParams';
import { Button } from '../ButtonVariants';

type SigninSignupProps = {
  isSignIn: boolean;
  setIsSignIn: React.Dispatch<React.SetStateAction<boolean>>;
};

const SigninSignup = ({ isSignIn }: SigninSignupProps) => {
  const navigate = useNavigate();
  const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);
  const { i18n, t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const [sendMagicCode, { loading: sendMagicCodeLoading }] = useSendMagicCodeMutation({
    onError: (error) => {
      showErrorToast({
        title: t('error.serverError'),
        message: error.message,
      });
    },
  });

  const [signup, { loading: signupMutationLoading }] = useSignupMutation({
    onError: (error) => {
      const message = error.message === 'Could not create user.' ? t('error.emaiExists') : error.message;
      showErrorToast({
        title: t('error.error'),
        message,
      });
    },
  });

  // const [getAuthUrl, { loading: getAuthUrlLoading }] = useGetAuthorizationUrlMutation({
  //   onError: (error) => {
  //     showErrorToast({
  //       title: t('error.serverError'),
  //       message: error.message,
  //     });
  //   },
  // });

  const { country, countryParamPrefix } = useCountryFromParams();

  const mutationsLoading = sendMagicCodeLoading || signupMutationLoading;
  const language = getLanguageByI18N(i18n.language);

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema(t)}
        validateOnBlur={isFirstSubmitAttempt}
        validateOnChange={isFirstSubmitAttempt}
        onSubmit={async (values) => {
          let response;

          if (isSignIn) {
            response = await sendMagicCode({ variables: { email: values.email, lang: language } });
          } else {
            response = await signup({ variables: { email: values.email, lang: language } });
            userVar(response.data?.signup.user);
          }

          if (response.data) {
            // TODO: Handle Correctly when signin/signup flow is split, treating all as registration for now
            // pushToGTMDataLayer(READ_ONLY_GTM_EVENTS.SEND_FORM_SUCCESS, READ_ONLY_GTM_EVENT_TYPES.AUTH_EMAIL_STEP1);
            pushToGTMDataLayer(READ_ONLY_GTM_EVENTS.SEND_FORM_SUCCESS, READ_ONLY_GTM_EVENT_TYPES.REG_EMAIL_STEP1);

            setSearchParams({ email: values.email });
            navigate(`${countryParamPrefix}auth/magic-code?email=${encodeURIComponent(values.email)}`);
            return;
          }
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form autoComplete="off">
              <div className="flex flex-col justify-center gap-6">
                <div className="flex flex-col justify-center gap-1 text-center">
                  <span className="text-xl font-semibold">{t('label.signinOrSignup')}</span>
                  <span className="text-sm text-blacky-gray">{t('label.wellSendYouCode')}</span>
                </div>
                <div className="flex flex-col justify-center gap-6">
                  <FormikInput
                    type="email"
                    name="email"
                    label={t('label.email')}
                    placeholder={t('placeholder.yourEmail')}
                    error={errors.email && touched.email ? errors.email : null}
                  />
                  <Button
                    type="submit"
                    size="m"
                    variant="primary"
                    onClick={() => setIsFirstSubmitAttempt(true)}
                    className="text-base"
                    loading={mutationsLoading}
                    // disabled={getAuthUrlLoading}
                  >
                    {t('button.label.continue')}
                  </Button>
                </div>
                {/* <div className="flex items-center justify-center">
                  <span className="h-[1px] w-full bg-satin-gray" />
                  <div className="flex justify-start shrink-0">
                    <span className="px-2 text-xs select-none text-silver-chalise">{t('or')}</span>
                  </div>
                  <span className="h-[1px] w-full bg-satin-gray" />
                </div> */}
                {/* <div className="grid grid-cols-[minmax(0,_1fr)] grid-rows-none items-stretch justify-start gap-3">
                  <Button
                    className="flex items-center justify-center h-10 gap-3 text-base rounded-full"
                    variant="outline"
                    classNames={{
                      label: 'flex items-center justify-center gap-3',
                    }}
                    loading={getAuthUrlLoading}
                    disabled={mutationsLoading}
                    loaderProps={{ type: 'dots' }}
                    onClick={async () => {
                      const { data } = await getAuthUrl({
                        variables: {
                          provider: Authorization_Provider.Google,
                        },
                      });

                      if (data) {
                        // TODO: Handle Correctly when signin/signup flow is split, treating all as registration for now
                        // pushToGTMDataLayer(
                        //   READ_ONLY_GTM_EVENTS.SEND_FORM_SUCCESS,
                        //   READ_ONLY_GTM_EVENT_TYPES.AUTH_GOOGLE_SUCCESS
                        // );
                        pushToGTMDataLayer(
                          READ_ONLY_GTM_EVENTS.SEND_FORM_SUCCESS,
                          READ_ONLY_GTM_EVENT_TYPES.REG_GOOGLE_SUCCESS
                        );

                        window.location.href = data.getAuthorizationUrl;
                      }
                    }}
                  >
                    <GoogleLogo height={24} />
                    <span className="m-0 text-base">{t('label.continueWithGoogle')}</span>
                  </Button>
                </div> */}
              </div>
            </Form>
          );
        }}
      </Formik>
      {/*<p className="mt-8 text-sm text-center text-blacky-gray">*/}
      {/*  {isSignIn ? t('label.dontHaveAcc') : t('label.alreadyHaveAcc')}{' '}*/}
      {/*  <button onClick={() => setIsSignIn((prev) => !prev)} className="text-blue-500 underline">*/}
      {/*    {isSignIn ? t('label.signup') : t('label.signin')}*/}
      {/*  </button>*/}
      {/*</p>*/}
      <div className="m-auto mt-6 w-fit">
        <LanguageSwitcher country={country} />
      </div>
    </>
  );
};

export default SigninSignup;
