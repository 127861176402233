import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { Loader } from '@mantine/core';

const buttonVariants = cva(
  'relative active:translate-y-[1px] flex items-center text-center justify-center my-auto disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-light-brand font-semibold hover:bg-light-orange',
        secondary: 'bg-angel-feather font-semibold hover:bg-feather-dark',
        ocean: 'bg-moroccan-day font-semibold hover:bg-light-blue',
      },
      size: {
        l: 'h-[60px] text-[20px] px-4 rounded-[24px]',
        m: 'h-12 text-[17px] px-4 rounded-[20px]',
        s: 'rounded-[18px] px-4 text-[17px] h-10 w-auto',
        xs: 'h-8 text-[14px] px-4 rounded-[14px]',
      },
      fullWidth: {
        true: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'm',
      fullWidth: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  fullWidth?: boolean;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, leftSection, rightSection, loading, variant, size, fullWidth, ...props }, ref) => {
    return (
      <button
        type="button"
        className={cn(
          buttonVariants({ variant, size, fullWidth, className }),
          loading && 'bg-angel-feather font-semibold hover:bg-feather-dark'
        )}
        ref={ref}
        {...props}
        disabled={props.disabled || loading}
      >
        {loading ? (
          <Loader type="dots" color="black" />
        ) : (
          <span className="inline-flex items-center justify-center">
            {leftSection && <span className="mr-2">{leftSection}</span>}
            <span className="flex-1">{children}</span>
            {rightSection && <span className="ml-2">{rightSection}</span>}
          </span>
        )}
      </button>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
