import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetItemsQueryVariables = Types.Exact<{
  searchParams?: Types.InputMaybe<Types.FindSearchParams>;
  params?: Types.InputMaybe<Types.FindPaginationParams>;
  excludeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetItemsQuery = {
  __typename?: 'Query';
  items: Array<{
    __typename?: 'Item';
    id: string;
    name?: string | undefined;
    type: Types.Item_Type;
    unitPrice?: number | undefined;
    unitType?: Types.Item_Unit_Type | undefined;
    taxRate?: number | undefined;
    vatRate?: Types.Item_Vat_Rate | undefined;
    vatExemptionReason?: string | undefined;
    withholdingTaxPercent?: number | undefined;
    withholdingTaxType?: Types.Invoice_Item_Withholding_Tax_Type | undefined;
    withholdingTaxAvailable?: boolean | undefined;
    withholdingTaxReason?: string | undefined;
  }>;
};

export const GetItemsDocument = gql`
  query GetItems($searchParams: FindSearchParams, $params: FindPaginationParams, $excludeIds: [ID!]) {
    items(searchParams: $searchParams, params: $params, excludeIds: $excludeIds) {
      id
      name
      type
      unitPrice
      unitType
      taxRate
      vatRate
      vatExemptionReason
      withholdingTaxPercent
      withholdingTaxType
      withholdingTaxAvailable
      withholdingTaxReason
    }
  }
`;

/**
 * __useGetItemsQuery__
 *
 * To run a query within a React component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsQuery({
 *   variables: {
 *      searchParams: // value for 'searchParams'
 *      params: // value for 'params'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useGetItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
}
export function useGetItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
}
export function useGetItemsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
}
export type GetItemsQueryHookResult = ReturnType<typeof useGetItemsQuery>;
export type GetItemsLazyQueryHookResult = ReturnType<typeof useGetItemsLazyQuery>;
export type GetItemsSuspenseQueryHookResult = ReturnType<typeof useGetItemsSuspenseQuery>;
export type GetItemsQueryResult = Apollo.QueryResult<GetItemsQuery, GetItemsQueryVariables>;
