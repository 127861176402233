import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelInvoiceMutationVariables = Types.Exact<{
  cancelInvoiceId: Types.Scalars['ID']['input'];
}>;

export type CancelInvoiceMutation = {
  __typename?: 'Mutation';
  cancelInvoice: {
    __typename?: 'Invoice';
    id: string;
    status: Types.Invoice_Status;
    createdAt?: Date | string | undefined;
    canceledAt?: Date | string | undefined;
    syncWithAt?:
      | {
          __typename?: 'InvoiceSyncWithAT';
          status?: Types.Invoice_Sync_With_At_Status | undefined;
          atCode?: string | undefined;
          atMessage?: string | undefined;
          systemError?: string | undefined;
          isRetriable?: boolean | undefined;
          updatedAt?: Date | string | undefined;
        }
      | undefined;
  };
};

export const CancelInvoiceDocument = gql`
  mutation CancelInvoice($cancelInvoiceId: ID!) {
    cancelInvoice(id: $cancelInvoiceId) {
      id
      status
      createdAt
      canceledAt
      syncWithAt {
        status
        atCode
        atMessage
        systemError
        isRetriable
        updatedAt
      }
    }
  }
`;
export type CancelInvoiceMutationFn = Apollo.MutationFunction<CancelInvoiceMutation, CancelInvoiceMutationVariables>;

/**
 * __useCancelInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvoiceMutation, { data, loading, error }] = useCancelInvoiceMutation({
 *   variables: {
 *      cancelInvoiceId: // value for 'cancelInvoiceId'
 *   },
 * });
 */
export function useCancelInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelInvoiceMutation, CancelInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelInvoiceMutation, CancelInvoiceMutationVariables>(CancelInvoiceDocument, options);
}
export type CancelInvoiceMutationHookResult = ReturnType<typeof useCancelInvoiceMutation>;
export type CancelInvoiceMutationResult = Apollo.MutationResult<CancelInvoiceMutation>;
export type CancelInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CancelInvoiceMutation,
  CancelInvoiceMutationVariables
>;
