import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSerieMutationVariables = Types.Exact<{
  serie: Types.SerieInput;
}>;


export type CreateSerieMutation = { __typename?: 'Mutation', createSerie: { __typename?: 'Serie', id: string, name?: string | undefined, status?: Types.Serie_Status | undefined, managementMode?: Types.Serie_Management_Mode | undefined, startDate?: Date | string | undefined, endDate?: Date | string | undefined, tenantId?: string | undefined, entries?: Array<{ __typename?: 'SerieEntry', documentType?: Types.Invoice_Document_Type | undefined, validationCode?: string | undefined, isVerified?: boolean | undefined } | undefined> | undefined } };


export const CreateSerieDocument = gql`
    mutation CreateSerie($serie: SerieInput!) {
  createSerie(serie: $serie) {
    id
    name
    status
    managementMode
    startDate
    endDate
    entries {
      documentType
      validationCode
      isVerified
    }
    tenantId
  }
}
    `;
export type CreateSerieMutationFn = Apollo.MutationFunction<CreateSerieMutation, CreateSerieMutationVariables>;

/**
 * __useCreateSerieMutation__
 *
 * To run a mutation, you first call `useCreateSerieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSerieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSerieMutation, { data, loading, error }] = useCreateSerieMutation({
 *   variables: {
 *      serie: // value for 'serie'
 *   },
 * });
 */
export function useCreateSerieMutation(baseOptions?: Apollo.MutationHookOptions<CreateSerieMutation, CreateSerieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSerieMutation, CreateSerieMutationVariables>(CreateSerieDocument, options);
      }
export type CreateSerieMutationHookResult = ReturnType<typeof useCreateSerieMutation>;
export type CreateSerieMutationResult = Apollo.MutationResult<CreateSerieMutation>;
export type CreateSerieMutationOptions = Apollo.BaseMutationOptions<CreateSerieMutation, CreateSerieMutationVariables>;