import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTenantSubtypeCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTenantSubtypeCategoriesQuery = {
  __typename?: 'Query';
  tenantSubtypeCategories: Array<{
    __typename?: 'TenantSubtypeCategory';
    id: string;
    localizations: Array<{
      __typename?: 'Localization';
      id: string;
      name: string;
      locale: string;
      tenantSubtypes: Array<{
        __typename?: 'TenantSubtype';
        id: string;
        name: string;
        icon?: string | undefined;
        locale: string;
      }>;
    }>;
  }>;
};

export const GetTenantSubtypeCategoriesDocument = gql`
  query GetTenantSubtypeCategories {
    tenantSubtypeCategories {
      id
      localizations {
        id
        name
        locale
        tenantSubtypes {
          id
          name
          icon
          locale
        }
      }
    }
  }
`;

/**
 * __useGetTenantSubtypeCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTenantSubtypeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantSubtypeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantSubtypeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantSubtypeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTenantSubtypeCategoriesQuery, GetTenantSubtypeCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantSubtypeCategoriesQuery, GetTenantSubtypeCategoriesQueryVariables>(
    GetTenantSubtypeCategoriesDocument,
    options
  );
}
export function useGetTenantSubtypeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantSubtypeCategoriesQuery, GetTenantSubtypeCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantSubtypeCategoriesQuery, GetTenantSubtypeCategoriesQueryVariables>(
    GetTenantSubtypeCategoriesDocument,
    options
  );
}
export function useGetTenantSubtypeCategoriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTenantSubtypeCategoriesQuery, GetTenantSubtypeCategoriesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTenantSubtypeCategoriesQuery, GetTenantSubtypeCategoriesQueryVariables>(
    GetTenantSubtypeCategoriesDocument,
    options
  );
}
export type GetTenantSubtypeCategoriesQueryHookResult = ReturnType<typeof useGetTenantSubtypeCategoriesQuery>;
export type GetTenantSubtypeCategoriesLazyQueryHookResult = ReturnType<typeof useGetTenantSubtypeCategoriesLazyQuery>;
export type GetTenantSubtypeCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetTenantSubtypeCategoriesSuspenseQuery
>;
export type GetTenantSubtypeCategoriesQueryResult = Apollo.QueryResult<
  GetTenantSubtypeCategoriesQuery,
  GetTenantSubtypeCategoriesQueryVariables
>;
