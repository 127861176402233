import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPortugalAtCredentialsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  level: Types.Tenant_Pt_At_Credentials_Level;
  credentials: Types.TenantCredentialsInput;
}>;


export type AddPortugalAtCredentialsMutation = { __typename?: 'Mutation', addPortugalATCredentials: { __typename?: 'Tenant', id: string, name?: string | undefined, postalCode?: string | undefined, taxPayerNumber?: string | undefined, territory?: Types.Tenant_Pt_Territory | undefined, country?: string | undefined, city?: string | undefined, address?: string | undefined, phone?: string | undefined, currency?: string | undefined, language?: string | undefined, isEnabledSyncWithAT?: boolean | undefined, onboarding?: { __typename?: 'TenantOnboarding', finished?: boolean | undefined, currentStep?: number | undefined } | undefined, credentials?: { __typename?: 'TenantCredentials', username?: string | undefined } | undefined } };


export const AddPortugalAtCredentialsDocument = gql`
    mutation AddPortugalATCredentials($id: ID!, $level: TENANT_PT_AT_CREDENTIALS_LEVEL!, $credentials: TenantCredentialsInput!) {
  addPortugalATCredentials(id: $id, level: $level, credentials: $credentials) {
    id
    name
    postalCode
    taxPayerNumber
    territory
    country
    city
    address
    phone
    currency
    language
    onboarding {
      finished
      currentStep
    }
    credentials {
      username
    }
    isEnabledSyncWithAT
  }
}
    `;
export type AddPortugalAtCredentialsMutationFn = Apollo.MutationFunction<AddPortugalAtCredentialsMutation, AddPortugalAtCredentialsMutationVariables>;

/**
 * __useAddPortugalAtCredentialsMutation__
 *
 * To run a mutation, you first call `useAddPortugalAtCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPortugalAtCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPortugalAtCredentialsMutation, { data, loading, error }] = useAddPortugalAtCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      level: // value for 'level'
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useAddPortugalAtCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<AddPortugalAtCredentialsMutation, AddPortugalAtCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPortugalAtCredentialsMutation, AddPortugalAtCredentialsMutationVariables>(AddPortugalAtCredentialsDocument, options);
      }
export type AddPortugalAtCredentialsMutationHookResult = ReturnType<typeof useAddPortugalAtCredentialsMutation>;
export type AddPortugalAtCredentialsMutationResult = Apollo.MutationResult<AddPortugalAtCredentialsMutation>;
export type AddPortugalAtCredentialsMutationOptions = Apollo.BaseMutationOptions<AddPortugalAtCredentialsMutation, AddPortugalAtCredentialsMutationVariables>;