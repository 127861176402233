import * as Types from '../types';

import { gql } from '@apollo/client';
export type CustomerCommonFieldsFragment = { __typename?: 'Customer', id: string, taxpayerNumber?: string | undefined, name?: string | undefined, photo?: string | undefined, companyName?: string | undefined, companyPosition?: string | undefined, email?: string | undefined, website?: string | undefined, description?: string | undefined, address?: string | undefined, postalCode?: string | undefined, country?: string | undefined, phone?: string | undefined, fax?: string | undefined, mobile?: string | undefined, city?: string | undefined };

export const CustomerCommonFieldsFragmentDoc = gql`
    fragment CustomerCommonFields on Customer {
  id
  taxpayerNumber
  name
  photo
  companyName
  companyPosition
  email
  website
  description
  address
  postalCode
  country
  phone
  fax
  mobile
  city
}
    `;