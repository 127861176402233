import React from 'react';
import { NotificationData, showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

type SuccessToastProps = Partial<NotificationData>;

export const showSuccessToast = (props: SuccessToastProps) => {
  const { title, message, id } = props;

  showNotification({
    id,
    withCloseButton: true,
    title,
    message,
    color: 'teal',
    icon: <IconCheck />,
    // classNames: classes,
    style: { backgroundColor: '#fff0cc' },
    loading: false,
  });
};
