// import React from 'react';
import { NotificationData, showNotification } from '@mantine/notifications';
// import { IconX } from '@tabler/icons-react';
import classes from './ErrorToast.module.css';

type ErrorToastProps = Partial<NotificationData>;

export const showErrorToast = (props: ErrorToastProps) => {
  const { title, message, id } = props;

  showNotification({
    id,
    withCloseButton: true,
    title,
    message,
    // color: 'red',
    // icon: <IconX />,
    classNames: classes,
    style: { backgroundColor: '#ff6347' },
    loading: false,
  });
};
