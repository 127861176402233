import * as Types from '../../types';

import { gql } from '@apollo/client';
import { InvoiceSummaryFragmentFragmentDoc } from '../../fragments/invoiceSummaryFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvoicesListPageQueryVariables = Types.Exact<{
  params?: Types.InputMaybe<Types.FindPaginationParams>;
  fromDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  aggregatedStatus?: Types.InputMaybe<Types.Invoice_Aggregated_Status>;
}>;

export type InvoicesListPageQuery = {
  __typename?: 'Query';
  firstInvoice: Array<{ __typename?: 'Invoice'; id: string }>;
  invoicesStats: {
    __typename?: 'InvoiceAggregatedTotalsStats';
    drafts?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
    overdue?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
    unpaid?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
    paid?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
  };
  invoices: Array<{
    __typename?: 'Invoice';
    id: string;
    number?: string | undefined;
    documentType?: Types.Invoice_Document_Type | undefined;
    status: Types.Invoice_Status;
    notes?: string | undefined;
    description?: string | undefined;
    date?: Date | string | undefined;
    dueDate?: Date | string | undefined;
    currency?: string | undefined;
    ownerUserId?: string | undefined;
    series?: { __typename?: 'InvoiceSerie'; ref?: string | undefined; name?: string | undefined } | undefined;
    customer?:
      | {
          __typename?: 'InvoiceCustomer';
          ref?: string | undefined;
          data?:
            | {
                __typename?: 'InvoiceCustomerData';
                taxpayerNumber?: string | undefined;
                name?: string | undefined;
                email?: string | undefined;
                postalCode?: string | undefined;
                country?: string | undefined;
                phone?: string | undefined;
                photo?: string | undefined;
              }
            | undefined;
        }
      | undefined;
    summary?:
      | {
          __typename?: 'InvoiceSummary';
          amountWithoutTax?: number | undefined;
          amountWithTax?: number | undefined;
          amountWithoutTaxWithoutDiscount?: number | undefined;
          globalDiscountType?: Types.Discount_Type | undefined;
          globalDiscountPercent?: number | undefined;
          globalDiscountAmount?: number | undefined;
          taxAmount?: number | undefined;
          total?: number | undefined;
          withholdingTaxAmount?: number | undefined;
          tax?:
            | Array<
                | {
                    __typename?: 'InvoiceSummaryTax';
                    name?: string | undefined;
                    rate?: number | undefined;
                    amount?: number | undefined;
                    incidence?: number | undefined;
                  }
                | undefined
              >
            | undefined;
        }
      | undefined;
    items?:
      | Array<
          | {
              __typename?: 'InvoiceItem';
              id: string;
              ref?: string | undefined;
              meta?:
                | {
                    __typename?: 'InvoiceItemMeta';
                    quantity?: number | undefined;
                    amountWithoutTax?: number | undefined;
                    amountWithTax?: number | undefined;
                    taxAmount?: number | undefined;
                    discountAmount?: number | undefined;
                    unitAmountWithoutTax?: number | undefined;
                    unitAmountWithTax?: number | undefined;
                    unitTaxAmount?: number | undefined;
                    unitDiscountAmount?: number | undefined;
                  }
                | undefined;
              data?:
                | {
                    __typename?: 'InvoiceItemData';
                    name?: string | undefined;
                    type: Types.Item_Type;
                    unitPrice?: number | undefined;
                    taxRate?: number | undefined;
                  }
                | undefined;
            }
          | undefined
        >
      | undefined;
    qrCode?: { __typename?: 'InvoiceQrCode'; data: string; imageData: string } | undefined;
    relatedDocuments?:
      | Array<{
          __typename?: 'Invoice';
          id: string;
          number?: string | undefined;
          documentType?: Types.Invoice_Document_Type | undefined;
          status: Types.Invoice_Status;
          date?: Date | string | undefined;
          dueDate?: Date | string | undefined;
          currency?: string | undefined;
          summary?:
            | {
                __typename?: 'InvoiceSummary';
                amountWithoutTax?: number | undefined;
                amountWithTax?: number | undefined;
                amountWithoutTaxWithoutDiscount?: number | undefined;
                globalDiscountType?: Types.Discount_Type | undefined;
                globalDiscountPercent?: number | undefined;
                globalDiscountAmount?: number | undefined;
                taxAmount?: number | undefined;
                total?: number | undefined;
                withholdingTaxAmount?: number | undefined;
                tax?:
                  | Array<
                      | {
                          __typename?: 'InvoiceSummaryTax';
                          name?: string | undefined;
                          rate?: number | undefined;
                          amount?: number | undefined;
                          incidence?: number | undefined;
                        }
                      | undefined
                    >
                  | undefined;
              }
            | undefined;
        }>
      | undefined;
  }>;
};

export const InvoicesListPageDocument = gql`
  query InvoicesListPage(
    $params: FindPaginationParams
    $fromDate: DateTime
    $toDate: DateTime
    $aggregatedStatus: INVOICE_AGGREGATED_STATUS
  ) {
    firstInvoice: invoices(params: { limit: 1 }) {
      id
    }
    invoicesStats(fromDate: $fromDate, toDate: $toDate) {
      drafts {
        count
        total
        taxAmount
        amountWithoutTax
      }
      overdue {
        count
        total
        taxAmount
        amountWithoutTax
      }
      unpaid {
        count
        total
        taxAmount
        amountWithoutTax
      }
      paid {
        count
        total
        taxAmount
        amountWithoutTax
      }
    }
    invoices(query: { fromDate: $fromDate, toDate: $toDate, aggregatedStatus: $aggregatedStatus }, params: $params) {
      id
      number
      documentType
      status
      notes
      series {
        ref
        name
      }
      description
      customer {
        ref
        data {
          taxpayerNumber
          name
          email
          postalCode
          country
          phone
          photo
        }
      }
      date
      dueDate
      currency
      summary {
        ...InvoiceSummaryFragment
      }
      items {
        id
        ref
        meta {
          quantity
          amountWithoutTax
          amountWithTax
          taxAmount
          discountAmount
          unitAmountWithoutTax
          unitAmountWithTax
          unitTaxAmount
          unitDiscountAmount
        }
        data {
          name
          type
          unitPrice
          taxRate
        }
      }
      qrCode {
        data
        imageData
      }
      relatedDocuments {
        id
        number
        documentType
        status
        date
        dueDate
        currency
        summary {
          ...InvoiceSummaryFragment
        }
      }
      ownerUserId
    }
  }
  ${InvoiceSummaryFragmentFragmentDoc}
`;

/**
 * __useInvoicesListPageQuery__
 *
 * To run a query within a React component, call `useInvoicesListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesListPageQuery({
 *   variables: {
 *      params: // value for 'params'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      aggregatedStatus: // value for 'aggregatedStatus'
 *   },
 * });
 */
export function useInvoicesListPageQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoicesListPageQuery, InvoicesListPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesListPageQuery, InvoicesListPageQueryVariables>(InvoicesListPageDocument, options);
}
export function useInvoicesListPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoicesListPageQuery, InvoicesListPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesListPageQuery, InvoicesListPageQueryVariables>(InvoicesListPageDocument, options);
}
export function useInvoicesListPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InvoicesListPageQuery, InvoicesListPageQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoicesListPageQuery, InvoicesListPageQueryVariables>(
    InvoicesListPageDocument,
    options
  );
}
export type InvoicesListPageQueryHookResult = ReturnType<typeof useInvoicesListPageQuery>;
export type InvoicesListPageLazyQueryHookResult = ReturnType<typeof useInvoicesListPageLazyQuery>;
export type InvoicesListPageSuspenseQueryHookResult = ReturnType<typeof useInvoicesListPageSuspenseQuery>;
export type InvoicesListPageQueryResult = Apollo.QueryResult<InvoicesListPageQuery, InvoicesListPageQueryVariables>;
