import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TaxExemptionReasonsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.TaxExemptionReasonFindQuery>;
}>;

export type TaxExemptionReasonsQuery = {
  __typename?: 'Query';
  taxExemptionReasons: Array<
    | {
        __typename?: 'TaxExemptionReason';
        id: string;
        name: string;
        label: string;
        description?:
          | { __typename?: 'TaxExemptionReasonDescription'; en?: string | undefined; pt?: string | undefined }
          | undefined;
      }
    | undefined
  >;
};

export const TaxExemptionReasonsDocument = gql`
  query TaxExemptionReasons($query: TaxExemptionReasonFindQuery) {
    taxExemptionReasons(query: $query) {
      id
      name
      label
      description {
        en
        pt
      }
    }
  }
`;

/**
 * __useTaxExemptionReasonsQuery__
 *
 * To run a query within a React component, call `useTaxExemptionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxExemptionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxExemptionReasonsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTaxExemptionReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<TaxExemptionReasonsQuery, TaxExemptionReasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxExemptionReasonsQuery, TaxExemptionReasonsQueryVariables>(
    TaxExemptionReasonsDocument,
    options
  );
}
export function useTaxExemptionReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaxExemptionReasonsQuery, TaxExemptionReasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxExemptionReasonsQuery, TaxExemptionReasonsQueryVariables>(
    TaxExemptionReasonsDocument,
    options
  );
}
export function useTaxExemptionReasonsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TaxExemptionReasonsQuery, TaxExemptionReasonsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TaxExemptionReasonsQuery, TaxExemptionReasonsQueryVariables>(
    TaxExemptionReasonsDocument,
    options
  );
}
export type TaxExemptionReasonsQueryHookResult = ReturnType<typeof useTaxExemptionReasonsQuery>;
export type TaxExemptionReasonsLazyQueryHookResult = ReturnType<typeof useTaxExemptionReasonsLazyQuery>;
export type TaxExemptionReasonsSuspenseQueryHookResult = ReturnType<typeof useTaxExemptionReasonsSuspenseQuery>;
export type TaxExemptionReasonsQueryResult = Apollo.QueryResult<
  TaxExemptionReasonsQuery,
  TaxExemptionReasonsQueryVariables
>;
