import * as Types from '../types';

import { gql } from '@apollo/client';
export type TenantCustomizationFieldsFragment = {
  __typename?: 'TenantCustomization';
  invoicing?:
    | {
        __typename?: 'TenantCustomizationInvoicing';
        logoFileId?: string | undefined;
        logoFile?: { __typename?: 'File'; id: string; url?: string | undefined; name?: string | undefined } | undefined;
      }
    | undefined;
};

export const TenantCustomizationFieldsFragmentDoc = gql`
  fragment TenantCustomizationFields on TenantCustomization {
    invoicing {
      logoFileId
      logoFile {
        id
        url
        name
      }
    }
  }
`;
