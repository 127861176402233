import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBankConnectionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetBankConnectionsQuery = {
  __typename?: 'Query';
  openBankingConnections: Array<{
    __typename?: 'OpenBankingConnection';
    id: string;
    name: string;
    logo?: string | undefined;
    provider: string;
    accounts?:
      | Array<
          | {
              __typename?: 'OpenBankingConnectionAccount';
              name: string;
              mask?: string | undefined;
              type: string;
              subtype: string;
            }
          | undefined
        >
      | undefined;
  }>;
};

export const GetBankConnectionsDocument = gql`
  query GetBankConnections {
    openBankingConnections {
      id
      name
      logo
      provider
      accounts {
        name
        mask
        type
        subtype
      }
    }
  }
`;

/**
 * __useGetBankConnectionsQuery__
 *
 * To run a query within a React component, call `useGetBankConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBankConnectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBankConnectionsQuery, GetBankConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankConnectionsQuery, GetBankConnectionsQueryVariables>(
    GetBankConnectionsDocument,
    options
  );
}
export function useGetBankConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankConnectionsQuery, GetBankConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBankConnectionsQuery, GetBankConnectionsQueryVariables>(
    GetBankConnectionsDocument,
    options
  );
}
export function useGetBankConnectionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetBankConnectionsQuery, GetBankConnectionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBankConnectionsQuery, GetBankConnectionsQueryVariables>(
    GetBankConnectionsDocument,
    options
  );
}
export type GetBankConnectionsQueryHookResult = ReturnType<typeof useGetBankConnectionsQuery>;
export type GetBankConnectionsLazyQueryHookResult = ReturnType<typeof useGetBankConnectionsLazyQuery>;
export type GetBankConnectionsSuspenseQueryHookResult = ReturnType<typeof useGetBankConnectionsSuspenseQuery>;
export type GetBankConnectionsQueryResult = Apollo.QueryResult<
  GetBankConnectionsQuery,
  GetBankConnectionsQueryVariables
>;
