import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateItemMutationVariables = Types.Exact<{
  item: Types.ItemInput;
}>;

export type CreateItemMutation = {
  __typename?: 'Mutation';
  createItem: {
    __typename?: 'Item';
    id: string;
    name?: string | undefined;
    type: Types.Item_Type;
    unitPrice?: number | undefined;
    unitType?: Types.Item_Unit_Type | undefined;
    vatRate?: Types.Item_Vat_Rate | undefined;
    taxRate?: number | undefined;
    vatExemptionReason?: string | undefined;
    withholdingTaxPercent?: number | undefined;
    withholdingTaxType?: Types.Invoice_Item_Withholding_Tax_Type | undefined;
    withholdingTaxAvailable?: boolean | undefined;
    withholdingTaxReason?: string | undefined;
  };
};

export const CreateItemDocument = gql`
  mutation CreateItem($item: ItemInput!) {
    createItem(item: $item) {
      id
      name
      type
      unitPrice
      unitType
      vatRate
      taxRate
      vatExemptionReason
      withholdingTaxPercent
      withholdingTaxType
      withholdingTaxAvailable
      withholdingTaxReason
    }
  }
`;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useCreateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
}
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
