import React from 'react';
import { Checkbox, CheckboxProps } from '@mantine/core';
import { useField } from 'formik';

interface FormikCheckboxProps extends CheckboxProps {
  name: string;
  error?: string;
}

const FormikCheckbox = (props: FormikCheckboxProps) => {
  const [field] = useField(props.name);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    } else {
      field.onChange(event);
    }
  };

  return <Checkbox {...props} {...field} id={props.name} onChange={handleOnChange} />;
};

export default FormikCheckbox;
