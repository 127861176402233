import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BusinessDataByTaxInfoQueryVariables = Types.Exact<{
  taxpayerNumber: Types.Scalars['String']['input'];
}>;

export type BusinessDataByTaxInfoQuery = {
  __typename?: 'Query';
  businessDataByTaxInfo: {
    __typename?: 'BusinessData';
    nif?: string | undefined;
    vat_region?: string | undefined;
    name?: string | undefined;
    address?: string | undefined;
    city?: string | undefined;
    email?: string | undefined;
    phone?: string | undefined;
    zip?: string | undefined;
    invoice_type?: string | undefined;
    cae?: Array<string | undefined> | undefined;
  };
};

export const BusinessDataByTaxInfoDocument = gql`
  query BusinessDataByTaxInfo($taxpayerNumber: String!) {
    businessDataByTaxInfo(taxpayerNumber: $taxpayerNumber) {
      nif
      vat_region
      name
      address
      city
      email
      phone
      zip
      invoice_type
      cae
    }
  }
`;

/**
 * __useBusinessDataByTaxInfoQuery__
 *
 * To run a query within a React component, call `useBusinessDataByTaxInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessDataByTaxInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessDataByTaxInfoQuery({
 *   variables: {
 *      taxpayerNumber: // value for 'taxpayerNumber'
 *   },
 * });
 */
export function useBusinessDataByTaxInfoQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessDataByTaxInfoQuery, BusinessDataByTaxInfoQueryVariables> &
    ({ variables: BusinessDataByTaxInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessDataByTaxInfoQuery, BusinessDataByTaxInfoQueryVariables>(
    BusinessDataByTaxInfoDocument,
    options
  );
}
export function useBusinessDataByTaxInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessDataByTaxInfoQuery, BusinessDataByTaxInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessDataByTaxInfoQuery, BusinessDataByTaxInfoQueryVariables>(
    BusinessDataByTaxInfoDocument,
    options
  );
}
export function useBusinessDataByTaxInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BusinessDataByTaxInfoQuery, BusinessDataByTaxInfoQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BusinessDataByTaxInfoQuery, BusinessDataByTaxInfoQueryVariables>(
    BusinessDataByTaxInfoDocument,
    options
  );
}
export type BusinessDataByTaxInfoQueryHookResult = ReturnType<typeof useBusinessDataByTaxInfoQuery>;
export type BusinessDataByTaxInfoLazyQueryHookResult = ReturnType<typeof useBusinessDataByTaxInfoLazyQuery>;
export type BusinessDataByTaxInfoSuspenseQueryHookResult = ReturnType<typeof useBusinessDataByTaxInfoSuspenseQuery>;
export type BusinessDataByTaxInfoQueryResult = Apollo.QueryResult<
  BusinessDataByTaxInfoQuery,
  BusinessDataByTaxInfoQueryVariables
>;
