import * as Types from '../../types';

import { gql } from '@apollo/client';
import { TenantCommonFieldsFragmentDoc } from '../../fragments/tenantFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTenantMutationVariables = Types.Exact<{
  tenant: Types.TenantInput;
}>;

export type CreateTenantMutation = {
  __typename?: 'Mutation';
  createTenant: {
    __typename?: 'Tenant';
    id: string;
    ownerUserId?: string | undefined;
    name?: string | undefined;
    commercialName?: string | undefined;
    cae?: Array<string | undefined> | undefined;
    postalCode?: string | undefined;
    taxPayerNumber?: string | undefined;
    territory?: Types.Tenant_Pt_Territory | undefined;
    country?: string | undefined;
    city?: string | undefined;
    verifiedAddressDetails?: boolean | undefined;
    address?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    currency?: string | undefined;
    type?: Types.Tenant_Type | undefined;
    subtype?: string | undefined;
    acceptedTosAndPrivacyPolicy?: boolean | undefined;
    language?: string | undefined;
    providesServices?: boolean | undefined;
    sellsGoods?: boolean | undefined;
    iban?: string | undefined;
    subtypeFreeInput?: string | undefined;
    isInitial?: boolean | undefined;
    isEnabledSyncWithAT?: boolean | undefined;
    customization?:
      | {
          __typename?: 'TenantCustomization';
          invoicing?:
            | {
                __typename?: 'TenantCustomizationInvoicing';
                logoFileId?: string | undefined;
                logoFile?:
                  | { __typename?: 'File'; id: string; url?: string | undefined; name?: string | undefined }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
    subtypeData?:
      | {
          __typename?: 'TenantSubtypeEntry';
          id: string;
          localizations: Array<{ __typename?: 'TenantSubtype'; id: string; name: string; locale: string }>;
        }
      | undefined;
    onboarding?:
      | {
          __typename?: 'TenantOnboarding';
          finished?: boolean | undefined;
          currentStep?: number | undefined;
          waitlist?: boolean | undefined;
        }
      | undefined;
    credentials?:
      | { __typename?: 'TenantCredentials'; username?: string | undefined; verified?: boolean | undefined }
      | undefined;
  };
};

export const CreateTenantDocument = gql`
  mutation CreateTenant($tenant: TenantInput!) {
    createTenant(tenant: $tenant) {
      ...TenantCommonFields
    }
  }
  ${TenantCommonFieldsFragmentDoc}
`;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useCreateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
}
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;
