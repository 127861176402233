import { PropsWithChildren } from 'react';
import FizLogo from '@/assets/fiz-icon-transparent.svg?react';

const AuthRouteContainer = ({ children }: PropsWithChildren) => {
  return (
    <div className="grid min-h-[100vh] min-w-[320px] grid-cols-[minmax(0,_455px)] grid-rows-[repeat(3,_min-content)] content-between justify-center bg-white-vanilla p-4">
      <div className="grid-row row-start-2 flex flex-col justify-center gap-6 pb-16 pt-4">{children}</div>
    </div>
  );
};

export default AuthRouteContainer;

AuthRouteContainer.Header = ({ children }: PropsWithChildren) => (
  <div className="flex flex-col justify-start gap-2">
    <div className="flex flex-col content-center items-center gap-2">{children}</div>
  </div>
);

AuthRouteContainer.Logo = () => <FizLogo height={48} />;
AuthRouteContainer.Title = ({ children }: PropsWithChildren) => (
  <h1 className="m-0 break-words text-center text-2xl font-semibold">{children}</h1>
);

AuthRouteContainer.Content = ({ children }: PropsWithChildren) => (
  <div className="contents">
    <div className="rounded-2xl border border-dark-burgundy bg-cream-gray p-12">{children}</div>
  </div>
);

AuthRouteContainer.Footer = ({ children }: PropsWithChildren) => children;
