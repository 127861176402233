import { Tenant } from '@/graphql-types';
import { useParams } from 'react-router-dom';

export const DEFAULT_COUNTRY = 'PT';
export const getCurrentCountry = (tenant?: Tenant) =>
  tenant?.country || localStorage.getItem('country') || DEFAULT_COUNTRY;
export const getPathPrefixByCountry = (country: string) =>
  country === DEFAULT_COUNTRY ? '/' : `/${country.toLowerCase()}/`;
export const useCountryFromParams = () => {
  const { country = DEFAULT_COUNTRY } = useParams();

  return { country: country.toUpperCase(), countryParamPrefix: getPathPrefixByCountry(country) };
};
