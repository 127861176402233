import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInvoicesStatsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetInvoicesStatsQuery = {
  __typename?: 'Query';
  invoicesStats: {
    __typename?: 'InvoiceAggregatedTotalsStats';
    drafts?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
    overdue?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
    unpaid?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
    paid?:
      | {
          __typename?: 'InvoiceAggregatedTotals';
          count?: number | undefined;
          total?: number | undefined;
          taxAmount?: number | undefined;
          amountWithoutTax?: number | undefined;
        }
      | undefined;
  };
};

export const GetInvoicesStatsDocument = gql`
  query GetInvoicesStats {
    invoicesStats {
      drafts {
        count
        total
        taxAmount
        amountWithoutTax
      }
      overdue {
        count
        total
        taxAmount
        amountWithoutTax
      }
      unpaid {
        count
        total
        taxAmount
        amountWithoutTax
      }
      paid {
        count
        total
        taxAmount
        amountWithoutTax
      }
    }
  }
`;

/**
 * __useGetInvoicesStatsQuery__
 *
 * To run a query within a React component, call `useGetInvoicesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>(GetInvoicesStatsDocument, options);
}
export function useGetInvoicesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>(GetInvoicesStatsDocument, options);
}
export function useGetInvoicesStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>(
    GetInvoicesStatsDocument,
    options
  );
}
export type GetInvoicesStatsQueryHookResult = ReturnType<typeof useGetInvoicesStatsQuery>;
export type GetInvoicesStatsLazyQueryHookResult = ReturnType<typeof useGetInvoicesStatsLazyQuery>;
export type GetInvoicesStatsSuspenseQueryHookResult = ReturnType<typeof useGetInvoicesStatsSuspenseQuery>;
export type GetInvoicesStatsQueryResult = Apollo.QueryResult<GetInvoicesStatsQuery, GetInvoicesStatsQueryVariables>;
