import * as Yup from 'yup';
import { TFunction } from 'i18next';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    // firstName: Yup.string().trim(),
    // lastName: Yup.string().trim(),
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.invalidEmailFormat'))
      .max(60, t('validation.emailTooLong')),
  });

export default validationSchema;
