import * as Types from '../../types';

import { gql } from '@apollo/client';
import { TenantCommonFieldsFragmentDoc } from '../../fragments/tenantFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantQueryVariables = Types.Exact<{
  tenantId: Types.Scalars['ID']['input'];
}>;

export type TenantQuery = {
  __typename?: 'Query';
  tenant: {
    __typename?: 'Tenant';
    id: string;
    ownerUserId?: string | undefined;
    name?: string | undefined;
    commercialName?: string | undefined;
    cae?: Array<string | undefined> | undefined;
    postalCode?: string | undefined;
    taxPayerNumber?: string | undefined;
    territory?: Types.Tenant_Pt_Territory | undefined;
    country?: string | undefined;
    city?: string | undefined;
    verifiedAddressDetails?: boolean | undefined;
    address?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    currency?: string | undefined;
    type?: Types.Tenant_Type | undefined;
    subtype?: string | undefined;
    acceptedTosAndPrivacyPolicy?: boolean | undefined;
    language?: string | undefined;
    providesServices?: boolean | undefined;
    sellsGoods?: boolean | undefined;
    iban?: string | undefined;
    subtypeFreeInput?: string | undefined;
    isInitial?: boolean | undefined;
    isEnabledSyncWithAT?: boolean | undefined;
    customization?:
      | {
          __typename?: 'TenantCustomization';
          invoicing?:
            | {
                __typename?: 'TenantCustomizationInvoicing';
                logoFileId?: string | undefined;
                logoFile?:
                  | { __typename?: 'File'; id: string; url?: string | undefined; name?: string | undefined }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
    subtypeData?:
      | {
          __typename?: 'TenantSubtypeEntry';
          id: string;
          localizations: Array<{ __typename?: 'TenantSubtype'; id: string; name: string; locale: string }>;
        }
      | undefined;
    onboarding?:
      | {
          __typename?: 'TenantOnboarding';
          finished?: boolean | undefined;
          currentStep?: number | undefined;
          waitlist?: boolean | undefined;
        }
      | undefined;
    credentials?:
      | { __typename?: 'TenantCredentials'; username?: string | undefined; verified?: boolean | undefined }
      | undefined;
  };
};

export const TenantDocument = gql`
  query Tenant($tenantId: ID!) {
    tenant(id: $tenantId) {
      ...TenantCommonFields
    }
  }
  ${TenantCommonFieldsFragmentDoc}
`;

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantQuery(
  baseOptions: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables> &
    ({ variables: TenantQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
}
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
}
export function useTenantSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TenantQuery, TenantQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
}
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantSuspenseQueryHookResult = ReturnType<typeof useTenantSuspenseQuery>;
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>;
