export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CountryCode: { input: string; output: string };
  Currency: { input: string; output: string };
  DateTime: { input: Date | string; output: Date | string };
  IBAN: { input: string; output: string };
  NonNegativeInt: { input: number; output: number };
  PhoneNumber: { input: string; output: string };
};

export enum Authorization_Provider {
  Google = 'GOOGLE',
}

export type AddressData = {
  __typename?: 'AddressData';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  vat?: Maybe<Scalars['Int']['output']>;
  vat_reduced?: Maybe<Scalars['Int']['output']>;
  vat_region?: Maybe<Scalars['String']['output']>;
  vat_super_reduced?: Maybe<Scalars['Int']['output']>;
  zip: Scalars['String']['output'];
};

export type AuthSession = {
  __typename?: 'AuthSession';
  callSource?: Maybe<Call_Source>;
  token: Scalars['String']['output'];
  user: User;
};

export type BusinessData = {
  __typename?: 'BusinessData';
  address?: Maybe<Scalars['String']['output']>;
  cae?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  goods?: Maybe<Scalars['Int']['output']>;
  invoice_type?: Maybe<Scalars['String']['output']>;
  invoice_types?: Maybe<BusinessDataInvoiceTypes>;
  legal_type?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nif?: Maybe<Scalars['String']['output']>;
  nifpt_left?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
  vat_reduced?: Maybe<Scalars['Int']['output']>;
  vat_region?: Maybe<Scalars['String']['output']>;
  vat_super_reduced?: Maybe<Scalars['Int']['output']>;
  warning?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type BusinessDataInvoiceTypes = {
  __typename?: 'BusinessDataInvoiceTypes';
  goods?: Maybe<Scalars['Int']['output']>;
  service?: Maybe<Scalars['Int']['output']>;
};

export enum Call_Source {
  Signin = 'SIGNIN',
  Signup = 'SIGNUP',
}

export type ConnectBankAccountResponse = {
  __typename?: 'ConnectBankAccountResponse';
  linkToken?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyPosition?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['Boolean']['output']>;
  /** Taxpayer Identification Number (e.g. NIF for Portugal) */
  taxpayerNumber?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type CustomerFindQuery = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyPosition?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['Boolean']['input']>;
  taxpayerNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyPosition?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  taxpayerNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export enum Discount_Type {
  Amount = 'AMOUNT',
  Percent = 'PERCENT',
}

export type ExportSaftptResponse = {
  __typename?: 'ExportSAFTPTResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
  ttl?: Maybe<Scalars['Int']['output']>;
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  onboardingWaitlistES: Scalars['Boolean']['output'];
  onboardingWaitlistIT: Scalars['Boolean']['output'];
  onboardingWaitlistPT: Scalars['Boolean']['output'];
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileFindQuery = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerUserId?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type FindPaginationParams = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type FindSearchParams = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum Generic_Service_Error_Type {
  AbilityPermissionDenied = 'ABILITY_PERMISSION_DENIED',
  AbilityTenantNotSet = 'ABILITY_TENANT_NOT_SET',
  AbilityUserNotSet = 'ABILITY_USER_NOT_SET',
  AtCreateSubuserInvalidCredentials = 'AT_CREATE_SUBUSER_INVALID_CREDENTIALS',
  AtCreateSubuserUnexpectedError = 'AT_CREATE_SUBUSER_UNEXPECTED_ERROR',
  AtCredentialsAlreadySet = 'AT_CREDENTIALS_ALREADY_SET',
  AtFailedToCreateSeries = 'AT_FAILED_TO_CREATE_SERIES',
  AtFailedToCreateSeriesAlreadyExists = 'AT_FAILED_TO_CREATE_SERIES_ALREADY_EXISTS',
  AtFailedToCreateSeriesNoActivity = 'AT_FAILED_TO_CREATE_SERIES_NO_ACTIVITY',
  AtFailedToVerifyCredentials = 'AT_FAILED_TO_VERIFY_CREDENTIALS',
  AtInvalidCredentials = 'AT_INVALID_CREDENTIALS',
  AtNoCredentialsToRemove = 'AT_NO_CREDENTIALS_TO_REMOVE',
  AtNoVerifiedCredentials = 'AT_NO_VERIFIED_CREDENTIALS',
  AtSubuserHasNoSeriesPermissions = 'AT_SUBUSER_HAS_NO_SERIES_PERMISSIONS',
  AuthExpiredOneTimeCode = 'AUTH_EXPIRED_ONE_TIME_CODE',
  AuthForbidden = 'AUTH_FORBIDDEN',
  AuthGenericError = 'AUTH_GENERIC_ERROR',
  AuthInvalidOneTimeCode = 'AUTH_INVALID_ONE_TIME_CODE',
  AuthPreviouslyUsedOneTimeCode = 'AUTH_PREVIOUSLY_USED_ONE_TIME_CODE',
  AuthTooManyAttempts = 'AUTH_TOO_MANY_ATTEMPTS',
  BillyFailedToCreateBusiness = 'BILLY_FAILED_TO_CREATE_BUSINESS',
  BillyInvoiceCreationFailed = 'BILLY_INVOICE_CREATION_FAILED',
  BillyNoItemInInvoice = 'BILLY_NO_ITEM_IN_INVOICE',
  BillyNoNifCustomerNotFound = 'BILLY_NO_NIF_CUSTOMER_NOT_FOUND',
  BillyResponseParsingError = 'BILLY_RESPONSE_PARSING_ERROR',
  EntityCustomerNotFound = 'ENTITY_CUSTOMER_NOT_FOUND',
  EntityInvoiceNotFound = 'ENTITY_INVOICE_NOT_FOUND',
  EntityItemNotFound = 'ENTITY_ITEM_NOT_FOUND',
  EntityNotFound = 'ENTITY_NOT_FOUND',
  EntitySerieNotFound = 'ENTITY_SERIE_NOT_FOUND',
  EntityTenantNotFound = 'ENTITY_TENANT_NOT_FOUND',
  ValidationAlreadyOnboarded = 'VALIDATION_ALREADY_ONBOARDED',
  ValidationBillyBusinessNotCreated = 'VALIDATION_BILLY_BUSINESS_NOT_CREATED',
  ValidationCannotCancelInvoiceInvoicesIssued = 'VALIDATION_CANNOT_CANCEL_INVOICE_INVOICES_ISSUED',
  ValidationCannotChangeNameInvoicesIssued = 'VALIDATION_CANNOT_CHANGE_NAME_INVOICES_ISSUED',
  ValidationCannotChangeProductNameInvoicesIssued = 'VALIDATION_CANNOT_CHANGE_PRODUCT_NAME_INVOICES_ISSUED',
  ValidationCannotChangeTaxpayerNumberInvoicesIssued = 'VALIDATION_CANNOT_CHANGE_TAXPAYER_NUMBER_INVOICES_ISSUED',
  ValidationCannotSetUnknownNif = 'VALIDATION_CANNOT_SET_UNKNOWN_NIF',
  ValidationCannotUpdateCredentials = 'VALIDATION_CANNOT_UPDATE_CREDENTIALS',
  ValidationCannotUpdateProductNoBillyUuid = 'VALIDATION_CANNOT_UPDATE_PRODUCT_NO_BILLY_UUID',
  ValidationCountryNotWaitlisted = 'VALIDATION_COUNTRY_NOT_WAITLISTED',
  ValidationCreditDebitNoteCannotBeIssued = 'VALIDATION_CREDIT_DEBIT_NOTE_CANNOT_BE_ISSUED',
  ValidationCreditDebitNoteParentRequired = 'VALIDATION_CREDIT_DEBIT_NOTE_PARENT_REQUIRED',
  ValidationCreditNoteFullyRectified = 'VALIDATION_CREDIT_NOTE_FULLY_RECTIFIED',
  ValidationCreditNoteParentRequired = 'VALIDATION_CREDIT_NOTE_PARENT_REQUIRED',
  ValidationCreditNoteTotalExceedsParent = 'VALIDATION_CREDIT_NOTE_TOTAL_EXCEEDS_PARENT',
  ValidationDuplicateTaxpayerNumber = 'VALIDATION_DUPLICATE_TAXPAYER_NUMBER',
  ValidationInitialTenantExists = 'VALIDATION_INITIAL_TENANT_EXISTS',
  ValidationInvalidInput = 'VALIDATION_INVALID_INPUT',
  ValidationInvalidPostalCode = 'VALIDATION_INVALID_POSTAL_CODE',
  ValidationNoBillyUuid = 'VALIDATION_NO_BILLY_UUID',
  ValidationNoBusinessUid = 'VALIDATION_NO_BUSINESS_UID',
  ValidationParentInvoiceNoBillyUuid = 'VALIDATION_PARENT_INVOICE_NO_BILLY_UUID',
  ValidationParentInvoiceRequired = 'VALIDATION_PARENT_INVOICE_REQUIRED',
  ValidationPoliciesNotAccepted = 'VALIDATION_POLICIES_NOT_ACCEPTED',
  ValidationSimplifiedInvoiceLimitExceeded = 'VALIDATION_SIMPLIFIED_INVOICE_LIMIT_EXCEEDED',
  ValidationSystemCustomerUpdate = 'VALIDATION_SYSTEM_CUSTOMER_UPDATE',
  ValidationTenantCannotUpdateNif = 'VALIDATION_TENANT_CANNOT_UPDATE_NIF',
  ValidationTenantDuplicateNif = 'VALIDATION_TENANT_DUPLICATE_NIF',
  ValidationTooManyTenants = 'VALIDATION_TOO_MANY_TENANTS',
  ValidationUnsupportedProductType = 'VALIDATION_UNSUPPORTED_PRODUCT_TYPE',
  ValidationUnsupportedProductUnitType = 'VALIDATION_UNSUPPORTED_PRODUCT_UNIT_TYPE',
  ValidationUnsupportedProductVatRate = 'VALIDATION_UNSUPPORTED_PRODUCT_VAT_RATE',
  ValidationUnsupportedTerritory = 'VALIDATION_UNSUPPORTED_TERRITORY',
}

export enum Invoice_Aggregated_Status {
  Draft = 'DRAFT',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}

export enum Invoice_Document_Type {
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  Invoice = 'INVOICE',
  InvoiceReceipt = 'INVOICE_RECEIPT',
  Receipt = 'RECEIPT',
  SimplifiedInvoice = 'SIMPLIFIED_INVOICE',
}

export enum Invoice_Item_Withholding_Tax_Type {
  Irc = 'IRC',
  Irs = 'IRS',
  Is = 'IS',
}

export enum Invoice_Status {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Draft = 'DRAFT',
  Issued = 'ISSUED',
  Paid = 'PAID',
  /** @deprecated No longer used */
  Processing = 'PROCESSING',
}

export enum Invoice_Sync_With_At_Status {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Synced = 'SYNCED',
}

export enum Item_Type {
  Product = 'PRODUCT',
  Service = 'SERVICE',
}

export enum Item_Unit_Type {
  Box = 'BOX',
  CubicMeter = 'CUBIC_METER',
  Day = 'DAY',
  Hour = 'HOUR',
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Meter = 'METER',
  Month = 'MONTH',
  Package = 'PACKAGE',
  SquareMeter = 'SQUARE_METER',
  Unit = 'UNIT',
  Week = 'WEEK',
}

export enum Item_Vat_Rate {
  Exempt = 'EXEMPT',
  Intermediate = 'INTERMEDIATE',
  Normal = 'NORMAL',
  Reduced = 'REDUCED',
  Reversecharge = 'REVERSECHARGE',
}

export type Invoice = {
  __typename?: 'Invoice';
  atcud?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<InvoiceCustomer>;
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Invoice_Document_Type>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  issuedAt?: Maybe<Scalars['DateTime']['output']>;
  issuer?: Maybe<InvoiceIssuer>;
  items?: Maybe<Array<Maybe<InvoiceItem>>>;
  notes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  parentDocument?: Maybe<Invoice>;
  parentInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  parentInvoiceId?: Maybe<Scalars['String']['output']>;
  parentInvoiceNumber?: Maybe<Scalars['String']['output']>;
  parentInvoiceType?: Maybe<Invoice_Document_Type>;
  payment?: Maybe<InvoicePayment>;
  qrCode?: Maybe<InvoiceQrCode>;
  reason?: Maybe<Scalars['String']['output']>;
  receipt?: Maybe<Invoice>;
  relatedDocuments?: Maybe<Array<Invoice>>;
  series?: Maybe<InvoiceSerie>;
  shortHash?: Maybe<Scalars['String']['output']>;
  status: Invoice_Status;
  summary?: Maybe<InvoiceSummary>;
  syncWithAt?: Maybe<InvoiceSyncWithAt>;
};

export type InvoiceAggregatedTotals = {
  __typename?: 'InvoiceAggregatedTotals';
  amountWithTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTax?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceAggregatedTotalsStats = {
  __typename?: 'InvoiceAggregatedTotalsStats';
  drafts?: Maybe<InvoiceAggregatedTotals>;
  issued?: Maybe<InvoiceAggregatedTotals>;
  overdue?: Maybe<InvoiceAggregatedTotals>;
  paid?: Maybe<InvoiceAggregatedTotals>;
  /** @deprecated Use `issued` instead */
  unpaid?: Maybe<InvoiceAggregatedTotals>;
};

export type InvoiceCustomer = {
  __typename?: 'InvoiceCustomer';
  data?: Maybe<InvoiceCustomerData>;
  ref?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCustomerData = {
  __typename?: 'InvoiceCustomerData';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  taxpayerNumber?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCustomerDataInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  taxpayerNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCustomerInput = {
  data?: InputMaybe<InvoiceCustomerDataInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceFindQuery = {
  aggregatedStatus?: InputMaybe<Invoice_Aggregated_Status>;
  currency?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<Invoice_Document_Type>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Invoice_Status>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<InvoiceCustomerInput>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: Invoice_Document_Type;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  items?: InputMaybe<Array<InvoiceItemInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  parentInvoiceId?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<InvoicePaymentInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<InvoiceSerieInput>;
  summary?: InputMaybe<InvoiceSummaryInput>;
};

export type InvoiceIssuer = {
  __typename?: 'InvoiceIssuer';
  data?: Maybe<InvoiceIssuerData>;
  ref?: Maybe<Scalars['String']['output']>;
};

export type InvoiceIssuerData = {
  __typename?: 'InvoiceIssuerData';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  commercialName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['Currency']['output']>;
  customization?: Maybe<TenantCustomization>;
  email?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['IBAN']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  taxPayerNumber?: Maybe<Scalars['String']['output']>;
  territory?: Maybe<Tenant_Pt_Territory>;
  type?: Maybe<Tenant_Type>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  data?: Maybe<InvoiceItemData>;
  id: Scalars['ID']['output'];
  meta?: Maybe<InvoiceItemMeta>;
  ref?: Maybe<Scalars['String']['output']>;
};

export type InvoiceItemData = {
  __typename?: 'InvoiceItemData';
  name?: Maybe<Scalars['String']['output']>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  taxRate?: Maybe<Scalars['Float']['output']>;
  type: Item_Type;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  unitType?: Maybe<Item_Unit_Type>;
  vatExemptionReason?: Maybe<Scalars['String']['output']>;
  vatRate?: Maybe<Item_Vat_Rate>;
  withholdingTaxAvailable?: Maybe<Scalars['Boolean']['output']>;
  withholdingTaxPercent?: Maybe<Scalars['Float']['output']>;
  withholdingTaxReason?: Maybe<Scalars['String']['output']>;
  withholdingTaxType?: Maybe<Invoice_Item_Withholding_Tax_Type>;
};

export type InvoiceItemDataInput = {
  name: Scalars['String']['input'];
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  type: Item_Type;
  unitPrice: Scalars['Float']['input'];
  unitType: Item_Unit_Type;
  vatExemptionReason?: InputMaybe<Scalars['String']['input']>;
  vatRate?: InputMaybe<Item_Vat_Rate>;
  withholdingTaxAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  withholdingTaxPercent?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxReason?: InputMaybe<Scalars['String']['input']>;
  withholdingTaxType?: InputMaybe<Invoice_Item_Withholding_Tax_Type>;
};

export type InvoiceItemInput = {
  data?: InputMaybe<InvoiceItemDataInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<InvoiceItemMetaInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceItemMeta = {
  __typename?: 'InvoiceItemMeta';
  amountWithTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTaxWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  unitAmountWithTax?: Maybe<Scalars['Float']['output']>;
  unitAmountWithTaxWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  unitAmountWithoutTax?: Maybe<Scalars['Float']['output']>;
  unitDiscountAmount?: Maybe<Scalars['Float']['output']>;
  unitTaxAmount?: Maybe<Scalars['Float']['output']>;
  withholdingTaxAmount?: Maybe<Scalars['Float']['output']>;
  withholdingTaxEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoiceItemMetaInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  unitDiscountPercent?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  date?: Maybe<Scalars['DateTime']['output']>;
  method?: Maybe<Scalars['String']['output']>;
};

export type InvoicePaymentInput = {
  date: Scalars['DateTime']['input'];
  method: Scalars['String']['input'];
};

export type InvoicePreviewData = {
  __typename?: 'InvoicePreviewData';
  items?: Maybe<Array<Maybe<InvoicePreviewItem>>>;
  summary?: Maybe<InvoicePreviewSummary>;
};

export type InvoicePreviewInput = {
  items?: InputMaybe<Array<InvoicePreviewItemInput>>;
  summary?: InputMaybe<InvoicePreviewSummaryInput>;
};

export type InvoicePreviewItem = {
  __typename?: 'InvoicePreviewItem';
  data?: Maybe<InvoicePreviewItemData>;
  meta?: Maybe<InvoicePreviewItemMeta>;
  ref?: Maybe<Scalars['String']['output']>;
};

export type InvoicePreviewItemData = {
  __typename?: 'InvoicePreviewItemData';
  taxRate?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  withholdingTaxPercent?: Maybe<Scalars['Float']['output']>;
  withholdingTaxType?: Maybe<Invoice_Item_Withholding_Tax_Type>;
};

export type InvoicePreviewItemDataInput = {
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  unitPrice: Scalars['Float']['input'];
  vatRate?: InputMaybe<Item_Vat_Rate>;
  withholdingTaxPercent?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxType?: InputMaybe<Invoice_Item_Withholding_Tax_Type>;
};

export type InvoicePreviewItemInput = {
  data?: InputMaybe<InvoicePreviewItemDataInput>;
  meta?: InputMaybe<InvoicePreviewItemMetaInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
};

export type InvoicePreviewItemMeta = {
  __typename?: 'InvoicePreviewItemMeta';
  amountWithTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTaxWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  unitAmountWithTax?: Maybe<Scalars['Float']['output']>;
  unitAmountWithTaxWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  unitAmountWithoutTax?: Maybe<Scalars['Float']['output']>;
  unitDiscountAmount?: Maybe<Scalars['Float']['output']>;
  unitTaxAmount?: Maybe<Scalars['Float']['output']>;
  withholdingTaxAmount?: Maybe<Scalars['Float']['output']>;
  withholdingTaxEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoicePreviewItemMetaInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  unitDiscountPercent?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxAmount?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoicePreviewSummary = {
  __typename?: 'InvoicePreviewSummary';
  amountWithTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTaxWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  globalDiscountAmount?: Maybe<Scalars['Float']['output']>;
  globalDiscountPercent?: Maybe<Scalars['Float']['output']>;
  globalDiscountType?: Maybe<Discount_Type>;
  tax?: Maybe<Array<Maybe<InvoiceSummaryTax>>>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  withholdingTaxAmount?: Maybe<Scalars['Float']['output']>;
};

export type InvoicePreviewSummaryInput = {
  globalDiscountAmount?: InputMaybe<Scalars['Float']['input']>;
  globalDiscountPercent?: InputMaybe<Scalars['Float']['input']>;
  globalDiscountType?: InputMaybe<Discount_Type>;
};

export type InvoiceQrCode = {
  __typename?: 'InvoiceQrCode';
  data: Scalars['String']['output'];
  imageData: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type InvoiceSerie = {
  __typename?: 'InvoiceSerie';
  name?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  validationCode?: Maybe<Scalars['String']['output']>;
};

export type InvoiceSerieInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  validationCode?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceSummary = {
  __typename?: 'InvoiceSummary';
  amountWithTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTax?: Maybe<Scalars['Float']['output']>;
  amountWithoutTaxWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  globalDiscountAmount?: Maybe<Scalars['Float']['output']>;
  globalDiscountPercent?: Maybe<Scalars['Float']['output']>;
  globalDiscountType?: Maybe<Discount_Type>;
  tax?: Maybe<Array<Maybe<InvoiceSummaryTax>>>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  withholdingTaxAmount?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceSummaryInput = {
  globalDiscountAmount?: InputMaybe<Scalars['Float']['input']>;
  globalDiscountPercent?: InputMaybe<Scalars['Float']['input']>;
  globalDiscountType?: InputMaybe<Discount_Type>;
};

export type InvoiceSummaryTax = {
  __typename?: 'InvoiceSummaryTax';
  amount?: Maybe<Scalars['Float']['output']>;
  incidence?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceSyncWithAt = {
  __typename?: 'InvoiceSyncWithAT';
  atCode?: Maybe<Scalars['String']['output']>;
  atMessage?: Maybe<Scalars['String']['output']>;
  isRetriable?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Invoice_Sync_With_At_Status>;
  systemError?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  taxRate?: Maybe<Scalars['Float']['output']>;
  type: Item_Type;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  unitType?: Maybe<Item_Unit_Type>;
  vatExemptionReason?: Maybe<Scalars['String']['output']>;
  vatRate?: Maybe<Item_Vat_Rate>;
  withholdingTaxAvailable?: Maybe<Scalars['Boolean']['output']>;
  withholdingTaxPercent?: Maybe<Scalars['Float']['output']>;
  withholdingTaxReason?: Maybe<Scalars['String']['output']>;
  withholdingTaxType?: Maybe<Invoice_Item_Withholding_Tax_Type>;
};

export type ItemFindQuery = {
  name?: InputMaybe<Scalars['String']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Item_Type>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitType?: InputMaybe<Item_Unit_Type>;
  vatExemptionReason?: InputMaybe<Scalars['String']['input']>;
  vatRate?: InputMaybe<Item_Vat_Rate>;
  withholdingTaxAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  withholdingTaxPercent?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxReason?: InputMaybe<Scalars['String']['input']>;
  withholdingTaxType?: InputMaybe<Invoice_Item_Withholding_Tax_Type>;
};

export type ItemInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  type: Item_Type;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitType?: InputMaybe<Item_Unit_Type>;
  vatExemptionReason?: InputMaybe<Scalars['String']['input']>;
  vatRate?: InputMaybe<Item_Vat_Rate>;
  withholdingTaxAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  withholdingTaxPercent?: InputMaybe<Scalars['Float']['input']>;
  withholdingTaxReason?: InputMaybe<Scalars['String']['input']>;
  withholdingTaxType?: InputMaybe<Invoice_Item_Withholding_Tax_Type>;
};

export enum Lang {
  En = 'EN',
  Es = 'ES',
  It = 'IT',
  Pt = 'PT',
}

export type Localization = {
  __typename?: 'Localization';
  id: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tenantSubtypes: Array<TenantSubtype>;
};

export type MobileAppInfo = {
  __typename?: 'MobileAppInfo';
  android: MobileAppInfoEntry;
  ios: MobileAppInfoEntry;
};

export type MobileAppInfoEntry = {
  __typename?: 'MobileAppInfoEntry';
  latestBuildVersion: Scalars['String']['output'];
  minBuildVersion: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addPortugalATCredentials: Tenant;
  cancelInvoice: Invoice;
  connectBankAccount: ConnectBankAccountResponse;
  createCustomer: Customer;
  createDraftInvoice: Invoice;
  createItem: Item;
  createSerie: Serie;
  createTenant: Tenant;
  deleteAccount: Scalars['Boolean']['output'];
  deleteInvoice: Invoice;
  getAuthorizationUrl: Scalars['String']['output'];
  issueInvoice: Invoice;
  markInvoicePaid: Invoice;
  onboardingConfirmTenant: Tenant;
  onboardingJoinWaitlist: Tenant;
  onboardingPrepareTenant: Tenant;
  removePortugalATCredentials: Tenant;
  retrySyncWithAT: Invoice;
  sendMagicCode: SendMagicCodeResponse;
  signin: AuthSession;
  signup: SignupResult;
  updateCustomer: Customer;
  updateInvoice: Invoice;
  updateItem: Item;
  updateSerie: Serie;
  updateTenant: Tenant;
};

export type MutationAddPortugalAtCredentialsArgs = {
  credentials: TenantCredentialsInput;
  id: Scalars['ID']['input'];
  level: Tenant_Pt_At_Credentials_Level;
};

export type MutationCancelInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationConnectBankAccountArgs = {
  metadata?: InputMaybe<Scalars['String']['input']>;
  publicToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCustomerArgs = {
  customer: CustomerInput;
};

export type MutationCreateDraftInvoiceArgs = {
  invoice: InvoiceInput;
};

export type MutationCreateItemArgs = {
  item: ItemInput;
};

export type MutationCreateSerieArgs = {
  serie: SerieInput;
};

export type MutationCreateTenantArgs = {
  tenant: TenantInput;
};

export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationGetAuthorizationUrlArgs = {
  provider: Authorization_Provider;
};

export type MutationIssueInvoiceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  invoice?: InputMaybe<InvoiceInput>;
};

export type MutationMarkInvoicePaidArgs = {
  id: Scalars['ID']['input'];
  payment: InvoicePaymentInput;
};

export type MutationOnboardingConfirmTenantArgs = {
  id: Scalars['ID']['input'];
  tenant: OnboardingConfirmTenantInput;
};

export type MutationOnboardingJoinWaitlistArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  tenant: OnboardingJoinWaitlistInput;
};

export type MutationOnboardingPrepareTenantArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  tenant: OnboardingPrepareTenantInput;
};

export type MutationRemovePortugalAtCredentialsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRetrySyncWithAtArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSendMagicCodeArgs = {
  email: Scalars['String']['input'];
  lang: Lang;
};

export type MutationSigninArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type MutationSignupArgs = {
  email: Scalars['String']['input'];
  lang: Lang;
};

export type MutationUpdateCustomerArgs = {
  customer: CustomerInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID']['input'];
  invoice: InvoiceInput;
};

export type MutationUpdateItemArgs = {
  id: Scalars['ID']['input'];
  item: ItemInput;
};

export type MutationUpdateSerieArgs = {
  id: Scalars['ID']['input'];
  serie: SerieInput;
};

export type MutationUpdateTenantArgs = {
  id: Scalars['ID']['input'];
  tenant: TenantInput;
};

export type OnboardingConfirmTenantInput = {
  acceptedTosAndPrivacyPolicy: Scalars['Boolean']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboarding: TenantOnboardingInput;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingJoinWaitlistInput = {
  acceptedTosAndPrivacyPolicy: Scalars['Boolean']['input'];
  country: Scalars['CountryCode']['input'];
  language: Scalars['String']['input'];
  onboarding: TenantOnboardingInput;
  subtype?: InputMaybe<Scalars['String']['input']>;
  subtypeFreeInput?: InputMaybe<Scalars['String']['input']>;
  type: Tenant_Type;
};

export type OnboardingPrepareTenantInput = {
  country: Scalars['CountryCode']['input'];
  language: Scalars['String']['input'];
  onboarding: TenantOnboardingInput;
  subtype?: InputMaybe<Scalars['String']['input']>;
  subtypeFreeInput?: InputMaybe<Scalars['String']['input']>;
  taxPayerNumber: Scalars['String']['input'];
  type: Tenant_Type;
};

export type OpenBankingConnection = {
  __typename?: 'OpenBankingConnection';
  accounts?: Maybe<Array<Maybe<OpenBankingConnectionAccount>>>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  plaid?: Maybe<OpenBankingConnectionPlaid>;
  provider: Scalars['String']['output'];
};

export type OpenBankingConnectionAccount = {
  __typename?: 'OpenBankingConnectionAccount';
  mask?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  subtype: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type OpenBankingConnectionPlaid = {
  __typename?: 'OpenBankingConnectionPlaid';
  institutionId: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  addressDataByPostalCode: AddressData;
  businessDataByTaxInfo: BusinessData;
  customer: Customer;
  customers: Array<Customer>;
  downloadInvoicePDF: File;
  exportSAFTPT: ExportSaftptResponse;
  featureFlags: FeatureFlags;
  invoice: Invoice;
  invoices: Array<Invoice>;
  invoicesStats: InvoiceAggregatedTotalsStats;
  item: Item;
  items: Array<Item>;
  me?: Maybe<User>;
  mobileApplication: MobileAppInfo;
  openBankingConnections: Array<OpenBankingConnection>;
  previewInvoice: InvoicePreviewData;
  serie: Serie;
  series: Array<Serie>;
  taxExemptionReasons: Array<Maybe<TaxExemptionReason>>;
  tenant: Tenant;
  tenantSubtypeCategories: Array<TenantSubtypeCategory>;
  tenants: Array<Tenant>;
};

export type QueryAddressDataByPostalCodeArgs = {
  country: Scalars['CountryCode']['input'];
  postalCode: Scalars['String']['input'];
};

export type QueryBusinessDataByTaxInfoArgs = {
  taxpayerNumber: Scalars['String']['input'];
};

export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCustomersArgs = {
  params?: InputMaybe<FindPaginationParams>;
  query?: InputMaybe<CustomerFindQuery>;
  searchParams?: InputMaybe<FindSearchParams>;
};

export type QueryDownloadInvoicePdfArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  invoice?: InputMaybe<InvoiceInput>;
  isDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryExportSaftptArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInvoicesArgs = {
  params?: InputMaybe<FindPaginationParams>;
  query?: InputMaybe<InvoiceFindQuery>;
  searchParams?: InputMaybe<FindSearchParams>;
};

export type QueryInvoicesStatsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryItemsArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  params?: InputMaybe<FindPaginationParams>;
  query?: InputMaybe<ItemFindQuery>;
  searchParams?: InputMaybe<FindSearchParams>;
};

export type QueryPreviewInvoiceArgs = {
  invoice: InvoicePreviewInput;
};

export type QuerySerieArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySeriesArgs = {
  params?: InputMaybe<FindPaginationParams>;
  query?: InputMaybe<SerieFindQuery>;
  searchParams?: InputMaybe<FindSearchParams>;
};

export type QueryTaxExemptionReasonsArgs = {
  query?: InputMaybe<TaxExemptionReasonFindQuery>;
};

export type QueryTenantArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTenantsArgs = {
  params?: InputMaybe<FindPaginationParams>;
  query?: InputMaybe<TenantFindQuery>;
  searchParams?: InputMaybe<FindSearchParams>;
};

export enum Serie_Management_Mode {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum Serie_Status {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
}

export type SendMagicCodeResponse = {
  __typename?: 'SendMagicCodeResponse';
  email: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Serie = {
  __typename?: 'Serie';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  entries?: Maybe<Array<Maybe<SerieEntry>>>;
  id: Scalars['ID']['output'];
  managementMode?: Maybe<Serie_Management_Mode>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Serie_Status>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type SerieEntry = {
  __typename?: 'SerieEntry';
  documentType?: Maybe<Invoice_Document_Type>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  validationCode?: Maybe<Scalars['String']['output']>;
};

export type SerieEntryInput = {
  documentType?: InputMaybe<Invoice_Document_Type>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  validationCode?: InputMaybe<Scalars['String']['input']>;
};

export type SerieFindQuery = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  managementMode?: InputMaybe<Serie_Management_Mode>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerUserId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Serie_Status>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type SerieInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  entries?: InputMaybe<Array<InputMaybe<SerieEntryInput>>>;
  managementMode?: InputMaybe<Serie_Management_Mode>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Serie_Status>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type SignupResult = {
  __typename?: 'SignupResult';
  callSource?: Maybe<Scalars['String']['output']>;
  user: User;
};

export enum Tenant_Pt_At_Credentials_Level {
  Main = 'MAIN',
  Subuser = 'SUBUSER',
}

export enum Tenant_Pt_Territory {
  Azores = 'AZORES',
  Continental = 'CONTINENTAL',
  Madeira = 'MADEIRA',
  Unknown = 'UNKNOWN',
}

export enum Tenant_Type {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
}

export type TaxExemptionReason = {
  __typename?: 'TaxExemptionReason';
  description?: Maybe<TaxExemptionReasonDescription>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TaxExemptionReasonDescription = {
  __typename?: 'TaxExemptionReasonDescription';
  en?: Maybe<Scalars['String']['output']>;
  pt?: Maybe<Scalars['String']['output']>;
};

export type TaxExemptionReasonFindQuery = {
  type?: InputMaybe<Item_Type>;
  vatRate?: InputMaybe<Item_Vat_Rate>;
};

export type Tenant = {
  __typename?: 'Tenant';
  acceptedTosAndPrivacyPolicy?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  cae?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  city?: Maybe<Scalars['String']['output']>;
  commercialName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  credentials?: Maybe<TenantCredentials>;
  currency?: Maybe<Scalars['Currency']['output']>;
  customization?: Maybe<TenantCustomization>;
  email?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['IBAN']['output']>;
  id: Scalars['ID']['output'];
  isEnabledSyncWithAT?: Maybe<Scalars['Boolean']['output']>;
  isInitial?: Maybe<Scalars['Boolean']['output']>;
  isRetailerOrStreetVendor?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onboarding?: Maybe<TenantOnboarding>;
  ownerUserId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  providesServices?: Maybe<Scalars['Boolean']['output']>;
  sellsGoods?: Maybe<Scalars['Boolean']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  subtypeData?: Maybe<TenantSubtypeEntry>;
  subtypeFreeInput?: Maybe<Scalars['String']['output']>;
  taxPayerNumber?: Maybe<Scalars['String']['output']>;
  territory?: Maybe<Tenant_Pt_Territory>;
  timezone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Tenant_Type>;
  verifiedAddressDetails?: Maybe<Scalars['Boolean']['output']>;
};

export type TenantCredentials = {
  __typename?: 'TenantCredentials';
  username?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type TenantCredentialsInput = {
  createNewSerie: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type TenantCustomization = {
  __typename?: 'TenantCustomization';
  invoicing?: Maybe<TenantCustomizationInvoicing>;
};

export type TenantCustomizationInput = {
  invoicing?: InputMaybe<TenantCustomizationInvoicingInput>;
};

export type TenantCustomizationInvoicing = {
  __typename?: 'TenantCustomizationInvoicing';
  logoFile?: Maybe<File>;
  logoFileId?: Maybe<Scalars['String']['output']>;
};

export type TenantCustomizationInvoicingInput = {
  logoFileId?: InputMaybe<Scalars['String']['input']>;
};

export type TenantFindQuery = {
  address?: InputMaybe<Scalars['String']['input']>;
  cae?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  currency?: InputMaybe<Scalars['Currency']['input']>;
  isRetailerOrStreetVendor?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboarding?: InputMaybe<TenantOnboardingInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  providesServices?: InputMaybe<Scalars['Boolean']['input']>;
  sellsGoods?: InputMaybe<Scalars['Boolean']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  taxPayerNumber?: InputMaybe<Scalars['String']['input']>;
  territory?: InputMaybe<Tenant_Pt_Territory>;
  type?: InputMaybe<Tenant_Type>;
};

export type TenantInput = {
  acceptedTosAndPrivacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  commercialName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  customization?: InputMaybe<TenantCustomizationInput>;
  iban?: InputMaybe<Scalars['IBAN']['input']>;
  isEnabledSyncWithAT?: InputMaybe<Scalars['Boolean']['input']>;
  isRetailerOrStreetVendor?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboarding?: InputMaybe<TenantOnboardingInput>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  providesServices?: InputMaybe<Scalars['Boolean']['input']>;
  sellsGoods?: InputMaybe<Scalars['Boolean']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  subtypeFreeInput?: InputMaybe<Scalars['String']['input']>;
  taxPayerNumber?: InputMaybe<Scalars['String']['input']>;
  territory?: InputMaybe<Tenant_Pt_Territory>;
  type?: InputMaybe<Tenant_Type>;
  verifiedAddressDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TenantOnboarding = {
  __typename?: 'TenantOnboarding';
  currentStep?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Boolean']['output']>;
  waitlist?: Maybe<Scalars['Boolean']['output']>;
};

export type TenantOnboardingInput = {
  currentStep: Scalars['Int']['input'];
  finished: Scalars['Boolean']['input'];
  waitlist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TenantSubtype = {
  __typename?: 'TenantSubtype';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TenantSubtypeCategory = {
  __typename?: 'TenantSubtypeCategory';
  id: Scalars['ID']['output'];
  localizations: Array<Localization>;
};

export type TenantSubtypeEntry = {
  __typename?: 'TenantSubtypeEntry';
  id: Scalars['String']['output'];
  localizations: Array<TenantSubtype>;
};

export type User = {
  __typename?: 'User';
  created_at?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  profile_picture_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};
