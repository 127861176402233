import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddressDataByPostalCodeQueryVariables = Types.Exact<{
  country: Types.Scalars['CountryCode']['input'];
  postalCode: Types.Scalars['String']['input'];
}>;

export type AddressDataByPostalCodeQuery = {
  __typename?: 'Query';
  addressDataByPostalCode: {
    __typename?: 'AddressData';
    city?: string | undefined;
    zip: string;
    vat_region?: string | undefined;
  };
};

export const AddressDataByPostalCodeDocument = gql`
  query AddressDataByPostalCode($country: CountryCode!, $postalCode: String!) {
    addressDataByPostalCode(country: $country, postalCode: $postalCode) {
      city
      zip
      vat_region
    }
  }
`;

/**
 * __useAddressDataByPostalCodeQuery__
 *
 * To run a query within a React component, call `useAddressDataByPostalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressDataByPostalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressDataByPostalCodeQuery({
 *   variables: {
 *      country: // value for 'country'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useAddressDataByPostalCodeQuery(
  baseOptions: Apollo.QueryHookOptions<AddressDataByPostalCodeQuery, AddressDataByPostalCodeQueryVariables> &
    ({ variables: AddressDataByPostalCodeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressDataByPostalCodeQuery, AddressDataByPostalCodeQueryVariables>(
    AddressDataByPostalCodeDocument,
    options
  );
}
export function useAddressDataByPostalCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressDataByPostalCodeQuery, AddressDataByPostalCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressDataByPostalCodeQuery, AddressDataByPostalCodeQueryVariables>(
    AddressDataByPostalCodeDocument,
    options
  );
}
export function useAddressDataByPostalCodeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AddressDataByPostalCodeQuery, AddressDataByPostalCodeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AddressDataByPostalCodeQuery, AddressDataByPostalCodeQueryVariables>(
    AddressDataByPostalCodeDocument,
    options
  );
}
export type AddressDataByPostalCodeQueryHookResult = ReturnType<typeof useAddressDataByPostalCodeQuery>;
export type AddressDataByPostalCodeLazyQueryHookResult = ReturnType<typeof useAddressDataByPostalCodeLazyQuery>;
export type AddressDataByPostalCodeSuspenseQueryHookResult = ReturnType<typeof useAddressDataByPostalCodeSuspenseQuery>;
export type AddressDataByPostalCodeQueryResult = Apollo.QueryResult<
  AddressDataByPostalCodeQuery,
  AddressDataByPostalCodeQueryVariables
>;
