import * as Types from '../../types';

import { gql } from '@apollo/client';
import { CustomerCommonFieldsFragmentDoc } from '../../fragments/customerFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerMutationVariables = Types.Exact<{
  updateCustomerId: Types.Scalars['ID']['input'];
  customer: Types.CustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'Customer', id: string, taxpayerNumber?: string | undefined, name?: string | undefined, photo?: string | undefined, companyName?: string | undefined, companyPosition?: string | undefined, email?: string | undefined, website?: string | undefined, description?: string | undefined, address?: string | undefined, postalCode?: string | undefined, country?: string | undefined, phone?: string | undefined, fax?: string | undefined, mobile?: string | undefined, city?: string | undefined } };


export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($updateCustomerId: ID!, $customer: CustomerInput!) {
  updateCustomer(id: $updateCustomerId, customer: $customer) {
    ...CustomerCommonFields
  }
}
    ${CustomerCommonFieldsFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      updateCustomerId: // value for 'updateCustomerId'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;